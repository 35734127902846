/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest, {
  apiSuccess,
  get,
  objectToQueryString,
  post,
  postForm,
  putForm,
} from 'utils/api/api';
import controlledCall from 'utils/services/controlledSaga';
import { appActions } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { asQuery, convertVideo, extension, unformatNumber, unformatParse } from 'utils/functions';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { LOTS_MANAGER, lotsManagerActions } from '../reducer/lotsManager.reducer';
// import crypto from 'crypto';

function* initialQuery() {
  yield takeLatest(LOTS_MANAGER.INITIAL_QUERY, function* (action) {
    const { lotId, auctionId } = action;

    yield put(appActions.setLoader('lots-manager', true));
    const result = yield controlledCall(
      get,
      `api/v1/lots/manager?${asQuery({ auctionId, lotId })}`,
      undefined,
      lotsManagerActions.initialQuerySuccess,
    );

    yield put(appActions.setLoader('lots-manager', false));
    if (result?.error) {
      history.push('/lots');
    }
  });
}

function* uploadLotDocument() {
  yield takeLatest(LOTS_MANAGER.UPLOAD_LOT_DOCUMENT, function* (action) {
    const { controls } = action;
    yield put(appActions.setLoader('adding-lot-image', true));
    const data = new FormData();
    data.append('file', controls.file);
    data.append('lotId', controls.lotId);
    data.append('documentTypeCode', controls.documentTypeCode);
    const response = yield apiRequest(
      'api/v1/lot_documents/',
      {
        method: 'post',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );
    yield put(appActions.setLoader('adding-lot-image', false));
    if (response) {
      yield put(lotsManagerActions.uploadLotDocumentSuccess(response));
    }
  });
}

function* destroyLotDocument() {
  yield takeLatest(LOTS_MANAGER.DESTROY_LOT_DOCUMENT, function* (action) {
    const { id } = action;
    const response = yield apiRequest(`api/v1/lot_documents/${id}`, { method: 'delete' });
    yield put(
      apiSuccess(LOTS_MANAGER.DESTROY_LOT_DOCUMENT_SUCCESS, {
        item: response,
        type: 'lotDocuments',
      }),
    );
  });
}

function* createLot() {
  yield takeLatest(LOTS_MANAGER.CREATE_LOT, function* (action) {
    const { controls, imagesFiles, saveAndContinue } = action;
    yield put(appActions.setLoader('lots-manager', true));

    const data = new FormData();
    for (const file of controls.attachedFiles) {
      data.append('attachedFiles[]', file, `${file.name}`);
    }
    for (const file of imagesFiles) {
      const now = moment().valueOf().toString();
      const random = uuidv4().split('-')[0];
      data.append('imagesFiles[]', file, `${now}-${random}.${extension(file.name)}`);
    }

    const body = JSON.stringify({
      lot: {
        lotCategoryId: controls.lotCategoryId,
        description: controls.description,
        index: unformatNumber(controls.index),
        fullDescription: controls.fullDescription,
        commission: controls.commission ? unformatParse(controls.commission) : null,
        sellerCommission: controls.sellerCommission ? unformatParse(controls.sellerCommission) : 0,
        auctionId: controls.auctionId,
        mandateId: controls.mandateId?.toString().trim().length === 0 ? null : controls.mandateId,
        mandateBaseId:
          controls.mandateBaseId?.toString().trim().length === 0 ? null : controls.mandateBaseId,
        newSellerId: controls.newSellerId,
        //   increase: controls.increase ? unformatParse(controls.increase) : 0,
        increase: controls.increase ? controls.increase : 0,
        urlVideo:
          controls.urlVideo != null && controls.urlVideo.trim().length > 0
            ? convertVideo(controls.urlVideo)
            : null,
        utilArea: !controls.utilArea
          ? null
          : controls.builtAreaUnitId?.toString() === '2'
          ? unformatParse(controls.utilArea) * 10000
          : unformatParse(controls.utilArea),
        stateId: controls.stateId,
        ivaCode: controls.ivaCode,
        unitId: controls.unitId,
        areaUnitId: controls.areaUnitId === '' ? '1' : controls.areaUnitId,
        builtAreaUnitId: controls.builtAreaUnitId === '' ? '1' : controls.builtAreaUnitId,
        price: controls.price ? controls.price : null,
        //  price: controls.price ? unformatParse(controls.price) : null,
        area: !controls.area
          ? null
          : controls.areaUnitId?.toString() === '2'
          ? unformatParse(controls.area) * 10000
          : unformatParse(controls.area),
        sale: controls.lotType === 'sale' || controls.lotType === 'lease',
        lease: controls.lotType === 'lease',
        lotType: controls.lotType,
        biddingEmail: controls.lotType === 'BID' ? controls.biddingEmail : '',
        name: controls.name?.toUpperCase(),
        guaranteeAmount:
          controls.guaranteeUnit === '1'
            ? controls.price * (controls.guaranteeAmount / 100)
            : controls.guaranteeAmount,
        minimumUnitId: controls.lotType === 'sale' ? controls.unitId : controls.minimumUnitId,
        brandModelId: controls.brandModelId || null,
        limitParticipants: controls.limitParticipants,
      },
      location: {
        address: controls.address,
        communeId: controls.communeId?.trim().length === 0 ? undefined : controls.communeId,
        regionId: controls.regionId?.trim().length === 0 ? undefined : controls.regionId,
        long: controls.long,
        lat: controls.lat,
      },
      entitiesIds: controls.entitiesIds,
      detailLines: controls.detailLines,
      lotImages: controls.lotImages,
      polygons: controls.polygons,
    });
    data.append('jsonBody', body);

    const result = yield controlledCall(
      postForm,
      'api/v1/lots',
      data,
      lotsManagerActions.createLotSuccess,
    );

    if (!result?.error) {
      const lotId = Object.values(result.objects.lots)[0].id;
      yield put(appActions.setSuccess('Se ha creado el lote exitosamente'));
      if (!saveAndContinue) {
        history.push(`/lots/${lotId}`);
      }
    }

    yield put(appActions.setLoader('lots-manager', false));
  });
}

function* editLot() {
  yield takeLatest(LOTS_MANAGER.EDIT_LOT, function* (action) {
    const { controls, imagesFiles } = action;
    yield put(appActions.setLoader('lots-manager', true));

    const data = new FormData();
    for (const file of controls.attachedFiles) {
      if ('size' in file) {
        data.append('attachedFiles[]', file, `${file.name}`);
      }
    }
    for (const file of imagesFiles) {
      const now = moment().valueOf().toString();
      const random = uuidv4().split('-')[0];
      data.append('imagesFiles[]', file, `${now}-${random}.${extension(file.name)}`);
    }
    const body = JSON.stringify({
      lot: {
        lotCategoryId: controls.lotCategoryId,
        description: controls.description,
        fullDescription: controls.fullDescription,
        index: unformatNumber(controls.index),
        commission: controls.commission ? unformatParse(controls.commission) : null,
        sellerCommission: controls.sellerCommission ? unformatParse(controls.sellerCommission) : 0,
        auctionId: controls.auctionId,
        mandateId: controls.mandateId?.toString().trim().length === 0 ? null : controls.mandateId,
        mandateBaseId:
          controls.mandateBaseId?.toString().trim().length === 0 ? null : controls.mandateBaseId,
        newSellerId: controls.newSellerId,
        urlVideo:
          controls.urlVideo != null && controls.urlVideo.trim().length > 0
            ? convertVideo(controls.urlVideo)
            : null,
        utilArea: !controls.utilArea
          ? null
          : controls.builtAreaUnitId?.toString() === '2'
          ? unformatParse(controls.utilArea) * 10000
          : unformatParse(controls.utilArea),
        increase: controls.increase ? unformatParse(controls.increase) : null,
        stateId: controls.stateId,
        ivaCode: controls.ivaCode === '-1' ? null : controls.ivaCode,
        unitId: controls.unitId,
        areaUnitId: controls.areaUnitId === '' ? '1' : controls.areaUnitId,
        builtAreaUnitId: controls.builtAreaUnitId === '' ? '1' : controls.builtAreaUnitId,
        // price: controls.price ? unformatParse(controls.price) : null,
        price: controls.price ? controls.price : null,
        area: !controls.area
          ? null
          : controls.areaUnitId?.toString() === '2'
          ? unformatParse(controls.area) * 10000
          : unformatParse(controls.area),
        sale: controls.lotType === 'sale' || controls.lotType === 'lease',
        lease: controls.lotType === 'lease',
        lotType: controls.lotType,
        biddingEmail: controls.lotType === 'BID' ? controls.biddingEmail : '',
        name: controls.name?.toUpperCase(),
        guaranteeAmount:
          controls.guaranteeUnit === '1'
            ? controls.price * (controls.guaranteeAmount / 100)
            : controls.guaranteeAmount,
        minimumUnitId: controls.lotType === 'sale' ? controls.unitId : controls.minimumUnitId,
        brandModelId: controls.brandModelId || null,
        limitParticipants: controls.limitParticipants,
      },
      location: {
        address: controls.address,
        communeId:
          controls.communeId?.toString().trim().length === 0 ? undefined : controls.communeId,
        regionId: controls.regionId?.toString().trim().length === 0 ? undefined : controls.regionId,
        long: controls.long,
        lat: controls.lat,
      },
      entitiesIds: controls.entitiesIds,
      detailLines: controls.detailLines,
      lotImages: controls.lotImages,
      polygons: controls.polygons,
      deletedFilesIds: controls.deletedFilesIds,
      deletedImagesIds: controls.deletedImages,
      imagesMeta: controls.imagesMeta,
    });

    data.append('jsonBody', body);

    const result = yield controlledCall(
      putForm,
      `api/v1/lots/${controls.lotId}`,
      data,
      lotsManagerActions.editLotSuccess,
    );
    if (!result?.error) {
      const { lotId } = result;
      yield put(appActions.setSuccess('Se ha editado el lote exitosamente'));
      if (!controls.saveAndContinue) {
        history.push(`/lots/${lotId}`);
      }
    }
    yield put(appActions.setLoader('lots-manager', false));
  });
}

function* satIntegration() {
  yield takeLatest(LOTS_MANAGER.SAT_INTEGRATION, function* (action) {
    const { license } = action;
    const body = { license };
    yield put(appActions.setLoader('integrating-sat-system', true));
    yield controlledCall(
      post,
      'api/v1/services/carLicenseInfo/',
      body,
      lotsManagerActions.satIntegrationSuccess,
      lotsManagerActions.satIntegrationFail,
    );
    yield put(appActions.setLoader('integrating-sat-system', false));
  });
} // getHistoricalLots
function* getHistory() {
  // getHistory
  yield takeLatest(LOTS_MANAGER.GET_HISTORY, function* (action) {
    yield put(appActions.setLoader('get-history', true));
    yield controlledCall(
      get,
      'api/v1/lots/getHistory',
      objectToQueryString(action.controls),
      lotsManagerActions.getHistorySuccess,
      lotsManagerActions.getHistoryFail,
    );
    yield put(appActions.setLoader('get-history', false));
  });
}

function* getBasicInfo() {
  yield takeLatest(LOTS_MANAGER.GET_BASIC_INFO, function* (action) {
    // yield put(appActions.setLoader('lots-manager', true));
    yield controlledCall(
      get,
      `api/v1/lots/info/${action.id}`,
      null,
      lotsManagerActions.getBasicInfoSuccess,
      () => {
        history.replace('/');
      },
    );
    // yield put(appActions.setLoader('lots-manager', false));
  });
}

export default function* lotsManagerSaga() {
  yield spawn(uploadLotDocument);
  yield spawn(destroyLotDocument);
  yield spawn(initialQuery);
  yield spawn(createLot);
  yield spawn(editLot);
  yield spawn(satIntegration);
  yield spawn(getHistory);
  yield spawn(getBasicInfo);
}
