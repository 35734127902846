import React, { useState, useEffect } from 'react';
import logo from 'utils/images/logo-tattersall.png';
import { Button, Typography, Box, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import StyledBox from 'utils/styledComponent/styledBox';
import { Link, useLocation } from 'react-router-dom';
import useApi from 'utils/hooks/useApi';
import { history } from 'utils/history';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextFieldForm from 'utils/formComponents/textField';
import { FIELD_TYPE, isTaxNumber, isValidRedirectUrl } from 'utils/validators';
import { asQuery, validateEmail } from 'utils/functions';
import VIEW from '../const/view';
import { LOGIN } from '../reducer/login.reducer';

function LoginForm(props) {
  const [error, setErrorView] = useState();
  const dispatch = useDispatch();
  const [textFieldDisabled] = useState(false);
  const { fetchData } = useApi();
  const location = useLocation();
  const { control, handleSubmit, setError } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const redirectUrl = urlParams.get('redirectUrl');
  const redirectDomain = urlParams.get('redirectDomain');
  const rolParam = urlParams.get('rol');
  const isRol = rolParam === 'true';
  const rolRoute = process.env.REACT_APP_ROL_HOME;

  const handleBackButton = () => {
    history.push(redirectUrl ?? `/`);
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let { emailTaxNumber } = data;
      const { password } = data;
      setErrorView('');
      if (!emailTaxNumber) {
        setError('emailTaxNumber', {
          type: 'manual',
          message: 'Ingrese un correo o RUT válidos',
        });
        return;
      }
      if (!password) {
        setError('password', {
          type: 'manual',
          message: 'Ingrese su contraseña',
        });
        return;
      }
      const isRut = isTaxNumber(emailTaxNumber);
      if (isRut === FIELD_TYPE.EMAIL) {
        const valid = validateEmail(emailTaxNumber);
        if (!valid) {
          setError('emailTaxNumber', {
            type: 'manual',
            message: 'Ingrese un correo o RUT válidos',
          });
          return;
        }
        emailTaxNumber = emailTaxNumber.toLowerCase();
      }
      const response = await fetchData({
        url: `login`,
        method: 'POST',
        body: {
          email: emailTaxNumber,
          password,
        },
      });
      if (!response?.token) {
        setErrorView('Correo, Rut o Contraseña incorrecta');
        return;
      }
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      dispatch({ type: LOGIN.LOGIN_USER_SUCCESS, payload: response });
      if (isRol) {
        // window.open(`${externalLink}/?param1=${session?.token}`, '_self');
        window.open(`${rolRoute}/?param1=${response.token}`, '_self');
        return;
      }
      if (location.state?.to) {
        history.push(location.state?.to);
        return;
      }
      if (redirectUrl) {
        if (location.state?.params) {
          history.push(`${redirectUrl}?${asQuery(location.state.params)}`);
          return;
        }
        history.push(redirectUrl);
        return;
      }
      if (redirectDomain) {
        if (isValidRedirectUrl(redirectDomain)) {
          return window.location.href = `${redirectDomain}?param=${response.token}`
        }
      }
      history.push('/');

    } catch (e) {
      setErrorView(JSON.stringify(e));
    } finally {
      setIsLoading(false);
    }
  };

  /* const onSubmit = async (data) => {
      try {
        setIsLoading(true);
        let { emailTaxNumber } = data;
        const { password } = data;
        setErrorView('');
        if (!emailTaxNumber) {
          setError('emailTaxNumber', {
            type: 'manual',
            message: 'Ingrese un correo o RUT válidos',
          });
          return;
        }
        if (!password) {
          setError('password', {
            type: 'manual',
            message: 'Ingrese su contraseña',
          });
          return;
        }
        const isRut = isTaxNumber(emailTaxNumber);
        if (isRut === FIELD_TYPE.EMAIL) {
          const valid = validateEmail(emailTaxNumber);
          if (!valid) {
            setError('emailTaxNumber', {
              type: 'manual',
              message: 'Ingrese un correo o RUT válidos',
            });
            return;
          }
          emailTaxNumber = emailTaxNumber.toLowerCase();
        }
        const response = await fetchData({
          url: `login`,
          method: 'POST',
          body: {
            email: emailTaxNumber,
            password,
          },
        });
        if (!response?.token) {
          setErrorView('Correo, Rut o Contraseña incorrecta');
          return;
        }
        localStorage.setItem('user', JSON.stringify({ token: response.token }));
        dispatch({ type: LOGIN.LOGIN_USER_SUCCESS, payload: response });
        if (isRol) {
          // window.open(`${externalLink}/?param1=${session?.token}`, '_self');
          window.open(`${rolRoute}/?param1=${response.token}`, '_self');
          return;
        }
        if (redirectUrl) {
          if (isValidRedirectUrl(redirectUrl)) {
            // Si es una URL válida, redirige al dominio externo
            window.location.href = redirectUrl;
            return;
          } else {
            // Si la URL no es válida, puedes mostrar un error o redirigir al home
            setErrorView('El redireccionamiento no es válido.');
            history.push('/');
            return;
          }
        }
      } catch (e) {
        setErrorView(JSON.stringify(e));
      } finally {
        setIsLoading(false);
      }
    }; */

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        background:
          'linear-gradient(0deg, rgba(7,42,104,1) 0%, rgba(0,23,64,1) 100%, rgba(32,75,154,1) 100%)',
      }}>
      <div
        style={{
          flex: 1,
          backgroundSize: 'contained',
          backgroundPositionX: '50%',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <StyledBox sx={{ maxWidth: 400 }}>
          <IconButton
            size="large"
            sx={{ position: 'absolute', left: 32 }}
            onClick={handleBackButton}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 4,
            }}>
            <img
              src={logo}
              style={{
                width: '70%',
                height: 'auto',
              }}
              alt="Texto alternativo"
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 4,
              }}>
              <Typography color="#5D6871" style={{ fontSize: 18 }}>
                ¡Bienvenido!
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 1,
              }}>
              <TextFieldForm
                disabled={textFieldDisabled}
                key="txt-emailTaxNumber"
                control={control}
                id="txt-emailTaxNumber"
                label="Email/RUT"
                name="emailTaxNumber"
                type="text"
                width="100%"
                rules={{
                  required: 'Este campo es obligatorio.',
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <TextFieldForm
                key="txt-password"
                control={control}
                id="txt-password"
                label="Contraseña"
                name="password"
                type="password"
                width="100%"
                rules={{
                  required: 'Este campo es obligatorio.',
                }}
              />
            </Box>
            <Typography sx={{ marginTop: 2 }} color="#e11">
              {error}
            </Typography>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 4,
                gap: 1,
              }}>
              <Link to="reset_password">
                <Typography sx={{ color: '#0D47A1', fontSize: 16 }}>
                  ¿Olvidaste tu Contraseña?
                </Typography>
              </Link>
              {/* cuestinar lo de abajo */}
              {!location?.state?.value ? (
                <Typography
                  sx={{ color: '#0D47A1', fontSize: 16, cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => {
                    // Construir parámetros opcionales
                    const queryParams = [];
                    if (redirectUrl) queryParams.push(`redirectUrl=${redirectUrl}`);
                    if (redirectDomain) queryParams.push(`redirectDomain=${redirectDomain}`);

                    // Generar la URL con los parámetros, si los hay
                    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
                    const targetUrl = `${VIEW.REGISTER}${queryString}`;

                    history.push(targetUrl, {
                      to: location.state?.to ? location.state?.to : undefined,
                    });
                  }}
                >
                  ¿No tienes una cuenta?
                </Typography>
              ) : null}
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 4,
            }}>
            <Button
              fullWidth
              sx={{
                backgroundColor: '#093170',
                ':hover': {
                  backgroundColor: '#2e5a9c', // Esto aclara el color un 20%
                },
              }}
              size="large"
              onClick={handleSubmit(onSubmit)}>
              {isLoading ? (
                <CircularProgress size={31} sx={{ color: '#fff' }} />
              ) : (
                <Typography style={{ color: '#fff', fontSize: 20 }}>SIGUIENTE</Typography>
              )}
            </Button>
          </Box>
        </StyledBox>
      </div>
    </div>
  );
}

export default LoginForm;
