/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import logo from 'utils/images/logo-tattersall.png';
import { Button, Typography, Checkbox, Box, IconButton, useMediaQuery } from '@mui/material';
import useApi from 'utils/hooks/useApi';
import TextFieldForm from 'utils/formComponents/textField';
import StyledBox from 'utils/styledComponent/styledBox';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { history } from 'utils/history';
import { useLocation } from 'react-router-dom';
import { formatRut, unformatRut } from 'utils/format';
import VIEW from '../const/view';

const rutValidation = (value) => {
  // Remover puntos y guión del RUT
  const cleanRut = value.replace(/\./g, '').replace(/-/g, '');

  // Separar dígito verificador del resto del RUT
  const rut = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1).toUpperCase();

  // Verificar que el RUT tenga 8 o 9 dígitos y que el dígito verificador sea válido
  if (!/^[0-9]+[0-9kK]{1}$/.test(value) || rut.length < 7) return 'RUT inválido';

  // Calcular dígito verificador esperado
  let suma = 0;
  let multiplo = 2;

  for (let i = 1; i <= rut.length; i++) {
    suma += parseInt(rut.charAt(rut.length - i)) * multiplo;
    multiplo = multiplo === 7 ? 2 : multiplo + 1;
  }

  const dvEsperado = 11 - (suma % 11);
  const dvCalculado = dvEsperado === 11 ? '0' : dvEsperado === 10 ? 'K' : dvEsperado.toString();

  if (dv !== dvCalculado) return 'RUT inválido';

  return true;
};

const emailValidation = (value) => {
  // Expresión regular para validar el formato del correo electrónico
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verificar si el valor coincide con la expresión regular
  if (!emailRegex.test(value)) return 'Correo electrónico inválido';

  return true;
};

export default function RegisterForm() {
  const { control, handleSubmit, watch } = useForm();
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const { fetchData } = useApi();
  const location = useLocation();
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const redirectUrl = urlParams.get('redirectUrl');
  const redirectDomain = urlParams.get('redirectDomain');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(520));

  const handleBackButton = () => {
    // Construir parámetros opcionales
    const queryParams = [];
    if (redirectUrl) queryParams.push(`redirectUrl=${redirectUrl}`);
    if (redirectDomain) queryParams.push(`redirectDomain=${redirectDomain}`);
    // Generar la URL con los parámetros opcionales
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    const targetUrl = `${VIEW.LOGIN}${queryString}`;
    history.push(targetUrl);
  };

  const textFields = [
    {
      defaultValue: '',
      id: 'txt-taxNumber',
      label: 'Rut',
      name: 'taxNumber',
      type: 'text',
      rules: {
        required: 'Este campo es obligatorio.',
        validate: rutValidation,
      },
      format: formatRut,
      unformat: unformatRut,
    },
    {
      defaultValue: '',
      id: 'txt-email',
      label: 'Correo Electronico',
      name: 'email',
      type: 'email',
      rules: {
        required: 'Este campo es obligatorio.',
        validate: emailValidation,
      },
    },
    {
      defaultValue: '',
      id: 'txt-name',
      label: 'Nombre',
      name: 'name',
      type: 'text',
      rules: { required: 'Este campo es obligatorio.' },
    },
    {
      defaultValue: '',
      id: 'txt-last-name',
      label: 'Apellido Paterno',
      name: 'lastName',
      type: 'text',
      rules: { required: 'Este campo es obligatorio.' },
    },
    {
      defaultValue: '',
      id: 'txt-second-lastname',
      label: 'Apellido Materno',
      name: 'secondLastName',
      type: 'text',
      rules: { required: 'Este campo es obligatorio.' },
    },
    {
      defaultValue: '',
      id: 'txt-phone',
      label: 'Telefono',
      name: 'phone',
      type: 'tel',
      rules: { required: 'Este campo es obligatorio.' },
    },
    {
      defaultValue: '',
      id: 'txt-password',
      label: 'Contraseña',
      name: 'password',
      type: 'password',
      helperText: 'Mínimo 6 carácteres, 1 mayúscula, 1 minúscula y 1 número.',
      rules: {
        required: 'Este campo es obligatorio.',
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/,
          message: 'Mínimo 6 carácteres, 1 mayúscula, 1 minúscula y 1 número.',
        },
      },
    },
    {
      defaultValue: '',
      id: 'txt-confirmPassword',
      label: 'Confirmar Contraseña',
      name: 'confirmPassword',
      type: 'password',
      rules: {
        required: 'Este campo es obligatorio.',
        validate: (value) => {
          if (value !== watch('password')) {
            return 'Las contraseñas no coinciden. Por favor, asegúrate de que las contraseñas sean iguales.';
          }
          return true;
        },
      },
    },
  ];
  const handleChangeCheckBox = () => {
    setChecked(!checked);
  };

  const onSubmit = async (data) => {
    let response;
    setError('');

    if (!checked) {
      setError(
        'Para completar el registro, es necesario que aceptes nuestros términos y condiciones.',
      );
      return;
    }

    try {
      response = await fetchData({
        url: `api/v1/users/sign_up_v2`,
        method: 'POST',
        body: data,
      });

      if (response && response.error) {
        setError(response.message);
        return;
      }

      // Construir parámetros opcionales
      const queryParams = [];
      if (redirectUrl) queryParams.push(`redirectUrl=${redirectUrl}`);
      if (redirectDomain) queryParams.push(`redirectDomain=${redirectDomain}`);

      // Generar la URL con los parámetros opcionales
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
      const targetUrl = `${VIEW.ACTIVE}${queryString}`;

      // Redirigir con los datos adicionales
      history.push(targetUrl, {
        registerTaxNumber: data.taxNumber,
        registerEmail: data.email.toLowerCase(),
        previusPath: `${VIEW.REGISTER}`,
        to: location.state?.to ? location.state?.to : undefined,
      });
    } catch (e) {
      setError(
        e.message ||
        'Lo siento, parece que ocurrió un error en el servidor mientras intentabas registrarte. Por favor, ponte en contacto con nuestro equipo de soporte para obtener ayuda con tu registro. Lamentamos las molestias que esto pueda causarte.',
      );
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(7,42,104,1) 0%, rgba(0,23,64,1) 100%, rgba(32,75,154,1) 100%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(644)]: {
          height: '100%',
        },
      }}>
      <Box>
        <StyledBox isMobile={isMobile} sx={{ minWidth: { xs: 150, sm: 500 }, maxWidth: 600 }}>
          <IconButton
            size="large"
            sx={{ position: 'absolute', left: 32 }}
            onClick={handleBackButton}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={logo}
              style={{
                width: '80%', // ajusta el ancho de la imagen al 80% del contenedor
                height: 'auto', // altura automática para mantener la proporción original
              }}
              alt="Texto alternativo"
            />
          </Box>
          <Typography textAlign="center" sx={{ mt: 2, mb: 2 }} style={{ fontSize: 16 }}>
            Solo necesitamos unos datos
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 1fr))',
              gap: 2,
              mx: isMobile ? 0 : 4,
            }}>
            {textFields.map((t) => (
              <TextFieldForm
                {...t}
                key={t.id}
                control={control}
                defaultValue={t.defaultValue}
                id={t.id}
                label={t.label}
                name={t.name}
                type={t.type}
                rules={t.rules}
                helperText={t.helperText}
              />
            ))}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}>
            <Checkbox
              checked={checked}
              onChange={handleChangeCheckBox}
              sx={{
                color: '#093170', // Cambia el color aquí
                transform: 'scale(1.5)',
              }}
            />
            <Typography sx={{ fontSize: 12 }}>
              Al registrarme, declaro haber leído, comprendido y aceptado en su totalidad las{' '}
              <a href={process.env.REACT_APP_PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
                políticas de privacidad
              </a>{' '}
              del sitio web
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: 2,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Typography sx={{ fontSize: 16, color: '#e11' }}>{error}</Typography>
          </Box>
          <Button
            size="large"
            variant="contained"
            sx={{
              width: '100%',
              fontSize: 20,
              //   marginTop: 1,
              backgroundColor: '#093170',
            }}
            onClick={handleSubmit(onSubmit)}>
            SIGUIENTE
          </Button>
        </StyledBox>
      </Box>
    </Box>
  );
}
