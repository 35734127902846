/* eslint-disable react/self-closing-comp */
import { Box, useMediaQuery } from '@mui/material';
import { appActions } from 'commons/reducer/commons.reducer';
import saga from 'commons/saga/commons.saga';
import { lotsActions } from 'modules/main/Lots/reducer/lots.reducer';
import { composedComponent } from 'utils/functions';
import Banner from './components/Banner';
import Footer from './components/Footer';
import LotsGrid from './components/LotsGrid';
import MyNavbar from './components/Navbar';
import NextAuction from './components/NextAuction';
import WhyChoose from './components/WhyChoose';

function NewHome(props) {
  const isSmallScreen = useMediaQuery('(max-width:1300px)');
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <MyNavbar layoutProps={props}></MyNavbar>
      <Box
        sx={{
          paddingTop: isSmallScreen ? 10 : 20,
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <Box sx={{ flex: 1 }}>
          <Banner />
        </Box>
      </Box>
      <WhyChoose></WhyChoose>
      <LotsGrid></LotsGrid>
      <NextAuction></NextAuction>
      <Footer></Footer>
    </Box>
  );
}
export default composedComponent(NewHome, saga, {
  saga: 'sagaApp',
  states: [
    'app.drawerIsOpen',
    'app.deviceError',
    'app.device',
    'app.errorMsg',
    'app.error',
    'app.successMsg',
    'app.success',
    'app.warningMsg',
    'app.warning',
    'app.gotoAuctionMsg',
    'app.emailReminderMsg',
    'app.guaranteeModal',
    'app.guaranteeData',
    'app.participateModal',
    'app.paymentModal',
    'app.paymentData',
    'app.gotoAuction',
    'app.emailReminder',
    'app.user',
    'app.objects',
    'app.formControls',
    'app.contactFormOpen',
    'app.contactLotId',
    'app.searchControls',
    'app.isLoading',
    'app.globals',
    'app.bgImage',
    'app.viewedGlobalDialog',
    'app.layoutStyles',
  ],
  actions: [
    {
      ...appActions,
      resetFilters: lotsActions.resetFilters,
      controlsChanged: lotsActions.controlsChanged,
    },
  ],
});

