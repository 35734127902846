/* eslint-disable react/react-in-jsx-scope */
import StarIcon from '@mui/icons-material/Star';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFetchData } from '../hooks/useFetchData';
import fontStyles from '../styles/fontStyles';

function WhyChoose() {
  const state = useFetchData(`/api/porque-elegirnos-gdas`);
  const { data } = state;
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#304e95',
          width: '100%',
          height: '380px',
          display: 'flex',
          flexDirection: 'column',
          color: '#ffffff',
          [theme.breakpoints.down(880)]: {
            height: '800px',
          },
        }}>
        {/* Text on Top */}
        <Box sx={{ flex: 0.3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h5" component="h5" sx={fontStyles.loteTitle}>
            ¿POR QUÉ ELEGIRNOS?
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 0.7,
            justifyContent: 'space-between',
            [theme.breakpoints.down(880)]: {
              flexDirection: 'column',
            },
          }}>
          {data &&
            data?.data.map((item) => (
              <Box
                sx={{
                  flex: 1,
                  textAlign: 'center',
                  p: 2,
                  px: 6,
                }}>
                <StarIcon sx={{ width: '80px', height: '80px' }} />
                <Typography variant="h5" component="h5" sx={fontStyles.whyChooseTitle}>
                  {item.attributes.title}
                </Typography>
                <Typography variant="h5" component="h5" sx={fontStyles.whyChooseDescription}>
                  {item.attributes.description}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', // Asegura que el texto y el Divider estén en columna
          alignItems: 'center', // Centra ambos elementos horizontalmente
          paddingY: 4,
        }}>
        <Typography
          variant="h4"
          component="h4"
          style={{
            color: '#304e95',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '35px',
            fontFamily: 'Trebuchet MS',
            textAlign: 'center',
            marginBottom: '10px', // Reduce el margen inferior para acercarlo al Divider
          }}>
          ¡TE ENSEÑAMOS LAS OPORTUNIDADES MÁS VISITADAS!
        </Typography>
        <Divider
          sx={{
            width: '60%', // Ajusta el ancho del subrayado
            borderColor: '#304e95', // Color del borde
            borderWidth: '2px', // Grosor del borde
            borderRadius: '2px', // Opcional: esquinas redondeadas para un efecto más suave
            marginTop: '5px', // Espacio entre el texto y el Divider
          }}
        />
      </Box>
    </>
  );
}

export default WhyChoose;

