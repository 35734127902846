import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

/* Material UI */
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';

import GavelIcon from '@mui/icons-material/Gavel';
import SyncIcon from '@mui/icons-material/Sync';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

/* Router */
import { useHistory } from 'react-router-dom';

/* Project */
import { ExternalMenu, ExternalPublicMenu, Menus, MenuTypes } from 'config/menus';
import { falsy, formatCLP, getAuctionTypeName, getItemInStorage } from 'utils/functions';
import { checkViewPermissions, externalLink } from 'utils/helpers/helper';
import NavBar from '../NavBar/Navbar';
import StyledTypography from '../StyledTypography/StyledTypography';
import styles, { inlineText, listItemStyles } from './styles';

const useStyles = makeStyles()(() => styles);

function SideBar(props) {
  const {
    user,
    balance,
    actions,
    isOpen,
    toggleDrawer,
    globals,
    propertiesIsLive,
    furnituresIsLive,
    propertyBalance,
  } = props;

  console.log('SideBar -> props', props);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const currentLive =
    propertiesIsLive && propertiesIsLive.AuctionType?.code !== 'BID'
      ? propertiesIsLive
      : furnituresIsLive;
  const islive = !!currentLive;

  const liveName = getAuctionTypeName(currentLive?.AuctionType?.code, currentLive?.areaId).live;

  const history = useHistory();
  const { classes } = useStyles();

  const onClickMenu = useCallback(
    (path, e) => {
      e.preventDefault();
      e.stopPropagation();
      actions.toggleDrawer();
      actions.globalFiltersReset();
      history.push(path);
    },
    [actions, history],
  );

  const mapView = useCallback(
    (index, path, options) => {
      // const auth = options.auth ?? Roles.ANY;
      const type = options.type ?? MenuTypes.PUBLIC;
      const anonymousCheck = type === MenuTypes.ANONYMOUS && falsy(user);
      // const publicCheck = type === MenuTypes.PUBLIC && (user?.roleId ? checkRole(user?.roleId, auth) : true);
      // const privateCheck = type === MenuTypes.PRIVATE && user != null && checkRole(user?.roleId, auth);
      const canView = anonymousCheck
        ? true
        : checkViewPermissions(user?.roleCode, options.permissionPath, user, options.areaID);

      if ((!anonymousCheck && !canView) || (isMobile && options.displayMobile === 'restrict')) {
        return null;
      }

      const Icon = options.icon;

      if (options.extraText) {
        return (
          <ListItem
            style={listItemStyles}
            onClick={(e) => onClickMenu(path, e)}
            button
            key={index}
            component="a"
            href={path}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: { fontSize: '1.06rem', fontWeight: 'bold', maxWidth: '80%' },
              }}
              primary={
                <>
                  <Typography
                    component="span"
                    className={inlineText}
                    style={{ fontWeight: 'bold' }}>
                    {options.name}
                  </Typography>
                  <Typography
                    component="span"
                    className={inlineText}
                    style={{ color: '#0d47a1', fontWeight: 'bold' }}>
                    {` ${options.extraText}`}
                  </Typography>
                </>
              }
            />
          </ListItem>
        );
      }

      return (
        <ListItem
          style={listItemStyles}
          onClick={(e) => onClickMenu(path, e)}
          button
          key={index}
          component="a"
          href={path}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: '1.06rem' } }}
            primary={options.name}
          />
        </ListItem>
      );
    },
    [onClickMenu, user, isMobile],
  );

  const views = useMemo(() => {
    if (isMobile) {
      return Object.keys(Menus)
        .filter((path) => Menus[path].name !== 'Remates bienes finalizados')
        .map((path, index) => mapView(index, path, Menus[path]))
        .filter((view) => view != null);
    }
    return Object.keys(Menus)
      .map((path, index) => mapView(index, path, Menus[path]))
      .filter((view) => view != null);
  }, [mapView, isMobile]);

  const externalOptions = useMemo(() => {
    const session = getItemInStorage('user');
    const hasLink = globals.rolExternalLink;
    if (hasLink && user?.id) {
      return Object.values(ExternalMenu(session, hasLink)).map((link) => {
        const canView = checkViewPermissions(user?.roleCode, 'external', {});
        if (!canView) return null;
        return link;
      });
    }
    return [];

    // eslint-disable-next-line
  }, [user?.id]);

  const externalPublicOptions = Object.values(ExternalPublicMenu());

  const externalRolLink = globals.rolExternalLink;

  const externalLinks = (
    <>
      {[...externalOptions, ...externalPublicOptions]
        .filter((link) => link !== null)
        .map(({ Icon, path, name }, ind) => (
          <ListItem
            button
            style={listItemStyles}
            component="a"
            href={path}
            target="_blank"
            key={`external-${ind}`}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
    </>
  );

  const startIcon = <GavelIcon style={{ color: islive && '#f44336' }} />;
  const buttonStyles = {
    marginBottom: '16px',
  };

  if (islive) {
    buttonStyles.animationName = '$blinker';
    buttonStyles.animationDuration = '1.5s';
    buttonStyles.animationTimingFunction = 'linear';
    buttonStyles.animationIterationCount = 'infinite';
  }

  const furnitureBalanceContent = (
    <Typography style={{ textAlign: 'center', margin: '16px 16px' }} component="div">
      <StyledTypography size="16px" weight={300} customColor="#5D6871" display="inline" mr="16px">
        Saldo Bienes:
      </StyledTypography>
      <StyledTypography size="16px" color="primary" display="inline">
        {formatCLP(balance)}
        <IconButton
          style={{ marginLeft: '8px' }}
          color="primary"
          onClick={() => actions.fetchBalance()}
          size="large">
          <SyncIcon />
        </IconButton>
      </StyledTypography>
    </Typography>
  );

  const propertyBalanceContent = (
    <Typography style={{ textAlign: 'center', margin: '16px 16px' }} component="div">
      <StyledTypography size="16px" weight={300} customColor="#5D6871" display="inline" mr="16px">
        Saldo Propiedades:
      </StyledTypography>
      <StyledTypography size="16px" color="primary" display="inline">
        {formatCLP(propertyBalance)}
      </StyledTypography>
    </Typography>
  );

  const handleLiveButtonClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (user) {
      return window.open(externalLink(getItemInStorage('user'), externalRolLink));
    }

    return history.push('/login');
  };

  const goToliveButton = (
    <Button
      component="a"
      color={!islive ? 'primary' : 'inherit'}
      startIcon={startIcon}
      style={buttonStyles}
      // onClick={() => window.open(`${globals.rolExternalLink}/auctionList`, '_blank', 'noopener')}
      onClick={handleLiveButtonClick}
      variant="contained"
      href={externalLink(getItemInStorage('user'), externalRolLink)}
      target="_blank">
      Ir {liveName}
    </Button>
  );

  return (
    <Drawer
      disableScrollLock
      classes={classes}
      open={isOpen}
      onClose={toggleDrawer}
      PaperProps={{ style: { overflow: 'hidden' } }}>
      <NavBar {...props} sideBar actions={actions} />
      <Box style={{ overflowY: 'auto' }}>
        {balance != null && (
          <div style={{ textAlign: 'center' }}>
            {furnitureBalanceContent}
            {propertyBalanceContent}
            {goToliveButton}
            <Divider />
          </div>
        )}
        <List>
          {views}
          {externalLinks}
        </List>
      </Box>
    </Drawer>
  );
}

SideBar.propTypes = {
  propertiesIsLive: PropTypes.object,
  furnituresIsLive: PropTypes.object,
};

export default SideBar;

