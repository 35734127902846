import React, { useEffect, useState } from 'react';
import { Grid, Container, useMediaQuery, useTheme, Modal, Box, Typography, Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Fade from '@mui/material/Fade';
// Project

import './styles.scss';
import { composedComponent } from 'utils/functions';
import propertyOne from 'utils/images/Property/Prop3min.jpg';
import propertyTwo from 'utils/images/Property/Prop2min.jpg';
import propertyThree from 'utils/images/Property/Prop1min.jpg';
import furnitureOne from 'utils/images/Furniture/Bienes3min.jpg';
import furnitureTwo from 'utils/images/Furniture/Bienes1min.jpg';
import furnitureThree from 'utils/images/Furniture/Bienes2min.jpg';
import MobileLayout from './components/MobileLayout';
import DesktopLayout from './components/DesktopLayout';
import { loginActions } from '../Login/reducer/login.reducer';
import styles from './styles';
import saga from './saga/home.saga';
import { useHistory } from 'react-router-dom';

import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';

function Home(props) {
  const { globals, user } = props;
  const { propertiesIsLive, furnituresIsLive } = globals;
  const theme = useTheme();
  const history = useHistory()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // sm
  const imagesOfProperties = [propertyOne, propertyTwo, propertyThree];
  const imagesOfFurniture = [furnitureOne, furnitureTwo, furnitureThree];

  const [propertyImg, setPropertyImg] = useState({ image: imagesOfProperties[0], index: 0 });
  const [furnitureImg, setFurnitureImg] = useState({ image: imagesOfFurniture[0], index: 0 });
  const [oldPropertyImg, setOldPropertyImg] = useState(imagesOfProperties[2]);
  const [oldFurnitureImg, setOldFurnitureImg] = useState(imagesOfFurniture[2]);
  const [isProperty, setIsProperty] = useState(true);
  const [open, setOpen] = useState();

  function changeImage() {
    if (isProperty) {
      const newIndex =
        imagesOfProperties.length - 1 === propertyImg.index ? 0 : propertyImg.index + 1;
      setPropertyImg({ image: imagesOfProperties[newIndex], index: newIndex });
    } else {
      const newIndex =
        imagesOfFurniture.length - 1 === furnitureImg.index ? 0 : furnitureImg.index + 1;
      setFurnitureImg({ image: imagesOfFurniture[newIndex], index: newIndex });
    }
    setIsProperty(!isProperty);
  }
  useEffect(() => {
    const newWebModal = localStorage.getItem("newWebModal");
    if (newWebModal === 'true') {
      history.replace('new_home')
    }
    else if (newWebModal !== 'false') {
      setOpen(true)
    }

  }, [])

  useEffect(() => {
    if (isProperty) {
      setOldPropertyImg(imagesOfProperties[propertyImg.index]);
    } else {
      setOldFurnitureImg(imagesOfFurniture[furnitureImg.index]);
    }
    const interval = setInterval(() => {
      changeImage();
    }, 6000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isProperty]);

  const mobileBackground = (
    <Grid container className={props.classes.containerBack} style={{ position: 'absolute' }}>
      <Grid item container lg={12} xs={12} className={props.classes.backProperties}>
        <Grid
          item
          lg={12}
          xs={12}
          className={props.classes.backProperties}
          style={{
            backgroundImage: `url(${oldPropertyImg})`,
            display: 'block',
            zIndex: -1,
            position: 'absolute',
          }}
        />
        {imagesOfProperties.map((img, index) => (
          <Fade
            in={index === propertyImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === propertyImg.index ? { timeout: 1500 } : {})}>
            <Grid
              item
              lg={12}
              xs={12}
              className={props.classes.backProperties}
              style={{
                backgroundImage: `url(${img})`,
                zIndex: index === propertyImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}
            />
          </Fade>
        ))}
      </Grid>
      <Grid item container lg={12} xs={12} className={props.classes.backFurniture}>
        <Grid
          item
          lg={12}
          xs={12}
          className={props.classes.backFurniture}
          // justifyContent={'flex-end'}
          style={{
            backgroundImage: `url(${oldFurnitureImg})`,
            display: 'block',
            zIndex: -1,
            position: 'absolute',
          }}
        />
        {imagesOfFurniture.map((img, index) => (
          <Fade
            in={index === furnitureImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === furnitureImg.index ? { timeout: 1500 } : {})}>
            <Grid
              key={index}
              item
              lg={12}
              xs={12}
              className={props.classes.backFurniture}
              // justifyContent='flex-end'
              style={{
                backgroundImage: `url(${img})`,
                zIndex: index === furnitureImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}
            />
          </Fade>
        ))}
      </Grid>
    </Grid>
  );

  const desktopBackground = (
    <>
      <Grid container className={props.classes.containerFilterBack}>
        <Grid container style={{ zIndex: -1, position: 'absolute' }}>
          <Grid
            item
            lg={12}
            xs={12}
            className={props.classes.backProperties}
            style={{ backgroundImage: `url(${oldPropertyImg})`, display: 'block' }}
          />
        </Grid>
        {imagesOfProperties.map((img, index) => (
          <Fade
            in={index === propertyImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === propertyImg.index ? { timeout: 1500 } : {})}>
            <Grid
              container
              style={{
                zIndex: index === propertyImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}>
              <Grid
                item
                lg={12}
                xs={12}
                className={props.classes.backProperties}
                style={{ backgroundImage: `url(${img})` }}
              />
            </Grid>
          </Fade>
        ))}
      </Grid>
      <Grid container className={props.classes.containerBack} style={{ position: 'absolute' }}>
        <Grid container style={{ zIndex: -1, position: 'absolute' }}>
          <Grid
            item
            lg={12}
            xs={12}
            className={props.classes.backFurniture}
            style={{ backgroundImage: `url(${oldFurnitureImg})`, display: 'block' }}
          />
        </Grid>
        {imagesOfFurniture.map((img, index) => (
          <Fade
            in={index === furnitureImg.index}
            key={index}
            style={{ transformOrigin: '0 0 0' }}
            {...(index === furnitureImg.index ? { timeout: 1500 } : {})}>
            <Grid
              container
              style={{
                zIndex: index === furnitureImg.index ? 0 : -500 + index,
                position: 'absolute',
              }}>
              <Grid item lg={4} xs={4} />
              <Grid
                key={index}
                item
                lg={8}
                xs={8}
                className={props.classes.backFurniture}
                style={{ backgroundImage: `url(${img})` }}
              />
            </Grid>
          </Fade>
        ))}
      </Grid>
    </>
  );

  const mobileLayout = (
    <MobileLayout
      classes={props.classes}
      propertyLive={propertiesIsLive}
      furnitureLive={furnituresIsLive}
      globals={globals}
      user={user}
    />
  );

  const desktopLayout = (
    <DesktopLayout
      classes={props.classes}
      propertyLive={propertiesIsLive}
      furnitureLive={furnituresIsLive}
      globals={globals}
      user={user}
    />
  );
  const handleClose = () => {
    localStorage.setItem("newWebModal", "false");
    setOpen(false)
  };
  const handleOk = () => {
    localStorage.setItem("newWebModal", "true");
    setOpen(false)
    history.replace('new_home')
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };
  const TattersallExperienceModal = (
    <Modal
      open={open}
      aria-labelledby="tattersall-modal-title"
      aria-describedby="tattersall-modal-description"
    >
      <Box sx={modalStyle}>
        <ImportantDevicesIcon sx={{ fontSize: 96, color: "#004C93" }} />
        <Typography id="tattersall-modal-title" variant="h4" component="h1" sx={{ mb: 2, color: "#004C93", fontWeight: 'bold' }}>
          ¡Nuevo Sitio Web!
        </Typography>
        <Typography id="tattersall-modal-description" sx={{ mb: 3 }}>
          Cada uno de nuestros negocios (Bienes, Propiedades, Campos, Arriendos) ahora cuenta con un sitio web propio.
          Navega fácilmente entre ellos usando la barra superior.
          <br />
          ¡Explora una experiencia más ágil y personalizada!
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mb: 1 }}
            onClick={handleOk}
          >
            Probar la Nueva Experiencia
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={handleClose}
          >
            No, gracias.
          </Button>
        </Box>
      </Box>
    </Modal >
  )
  return (
    <Container maxWidth="lg">
      {TattersallExperienceModal}
      {isMobile ? mobileBackground : desktopBackground}
      <Grid container className={props.classes.detailsContainer} alignItems="center">
        {isMobile ? mobileLayout : desktopLayout}
      </Grid>
    </Container>
  );
}

export default withStyles(
  composedComponent(Home, saga, {
    saga: 'sagaHome',
    states: ['app.objects', 'app.globals', 'app.user'],
    actions: [loginActions],
  }),
  styles,
);
