import { useState } from 'react';

export function usePostData(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const postData = async (body) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ALB}/api/v1${url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log(result);
      setData(result);
      return result; // Return the result to be used in handleClick
    } catch (error) {
      setError(error.message);
      throw error; // Throw error to be caught in handleClick
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    postData, // Return the postData function
  };
}

