const fontStyles = {
  button: {
    color: '#314D95',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: { lg: '15px', xs: '15px' }, // '55px' for font-size
    lineHeight: '30px', // '77px' for line-height
    fontFamily: 'Trebuchet MS',
  },
  buttonWhite: {
    color: 'white',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '18px', // '55px' for font-size
    lineHeight: '30px', // '77px' for line-height
    fontFamily: 'Trebuchet MS',
    whiteSpace: 'nowrap',
    margin: 0,
  },
  descriptionText: {
    color: '#304e95',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: { lg: '20px', xs: '22px' }, // '55px' for font-size
    lineHeight: '30px', // '77px' for line-height
    fontFamily: 'Helvetica',
  },
  titleText: {
    color: '#304e95',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: { lg: '26px', xs: '26px' }, // '55px' for font-size
    lineHeight: '30px', // '77px' for line-height
    fontFamily: 'Trebuchet MS',
  },
  navbarText: {
    font: 'normal normal normal 14px/16px Trebuchet MS',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    ':hover': {
      color: '#dadfea',
    },
  },
  navbarTextGrey: {
    font: 'normal normal normal 14px/16px Trebuchet MS',
    whiteSpace: 'nowrap',
    color: '#191d1e',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    ':hover': {
      color: '#dadfea',
    },
  },
  titleTextWhite: {
    color: 'white',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: { lg: '32px', xs: '30px' }, // '55px' for font-size
    lineHeight: '30px', // '77px' for line-height
    fontFamily: 'Trebuchet MS',
    margin: 0,
  },
  loteTitle: {
    fontStyle: 'normal', // equivalent to 'normal' in the shorthand
    fontWeight: 'bold', // equivalent to 'bold' in the shorthand
    fontSize: { lg: '27px', xs: '22px' }, // equivalent to '20px' in the shorthand
    lineHeight: '29px', // equivalent to '30px' in the shorthand
    fontFamily: 'Trebuchet MS',
  },
  loteDescription: {
    fontStyle: 'normal', // equivalent to 'normal' in the shorthand
    fontWeight: 'normal', // equivalent to 'bold' in the shorthand
    fontSize: { lg: '24px', xs: '20px' }, // equivalent to '20px' in the shorthand
    lineHeight: '29px', // equivalent to '30px' in the shorthand
    fontFamily: 'Trebuchet MS',
  },
  howToTitle: {
    color: '#304e95',
    fontStyle: 'normal', // 'normal' for font-style
    fontWeight: 'normal', // 'normal' for font-weight
    fontSize: { lg: '35px', xs: '31px' }, // '55px' for font-size
    lineHeight: '50px', // '77px' for line-height
    fontFamily: 'Officina Sans Std',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
  },
  howToRemate: {
    color: '#304e95',
    fontWeight: 'bold',
    fontStyle: 'normal', // 'normal' for font-style
    fontSize: '85px', // '55px' for font-size
    lineHeight: '110px', // '77px' for line-height
    fontFamily: 'Officina Sans Std Bold',
    whiteSpace: 'nowrap',
    marginTop: '-20px',
  },
  saberMasBoton: {
    backgroundColor: 'transparent', // Make the background transparent
    padding: '5px 20px',
    borderRadius: '5px',
    border: '1px solid #314D95', // Add a border with the desired color
    cursor: 'pointer', // Optional: Add a pointer cursor on hover
    width: '35%',
    height: '41px',
    boxShadow: '0px 3px 6px #00000029',
  },
  socialMedia: {
    color: '#304e95',
    fontWeight: 'bold',
    fontStyle: 'normal', // 'normal' for font-style
    fontSize: '100px', // '55px' for font-size
    lineHeight: '155px', // '77px' for line-height
    fontFamily: 'Officina Sans Std Bold',
    whiteSpace: 'nowrap',
    marginTop: '-20px',
  },
  findUs: {
    color: '#304e95',
    fontStyle: 'normal', // 'normal' for font-style
    fontWeight: 'normal', // 'normal' for font-weight
    fontSize: '50px', // '55px' for font-size
    lineHeight: '77px', // '77px' for line-height
    fontFamily: 'Officina Sans Std',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
  },
  drawerText: {
    fontStyle: 'normal', // equivalent to 'normal' in the shorthand
    fontWeight: 'normal', // equivalent to 'bold' in the shorthand
    fontSize: '12px', // equivalent to '20px' in the shorthand
    lineHeight: '15px', // equivalent to '30px' in the shorthand
    fontFamily: 'Trebuchet MS',
    whiteSpace: 'nowrap',
    color: '9c9c9d',
  },
  iconDrawer: {
    fontSize: '18px',
  },
  whyChooseTitle: {
    font: 'normal normal bold 20px/22px Trebuchet MS',
  },
  whyChooseDescription: {
    font: 'normal normal normal 16px/18px Trebuchet MS',
  },
  nextAuctionTitle: {
    font: 'normal normal normal 45px/50px Officina Sans Std',
    color: '#314D95',
  },
  nextAuctionTitle2: {
    font: 'normal normal bold 85px/100px Officina Sans Std',
    color: '#314D95',
  },
  nextAuctionDescription: {
    font: 'oblique normal 300 25px/28px Helvetica',
    color: '#314D95',
  },
  nextAuctionButton: {
    font: 'normal normal bold 18px/22px Trebuchet MS',
    color: '#314D95',
  },
};

export default fontStyles;

