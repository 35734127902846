import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import Icon from '../../../../commons/assets/icons/Icono-Tractor.png';

function IconBox(props) {
  const { title, description, url, icon } = props;

  const isXs = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        padding: 3,
        width: { sm: 220, xs: 100 },
        height: { lg: 200, sm: 180, xs: 160 },
        borderRadius: '9px',
        bgcolor: '#ffffff',
        boxShadow: '0px 3px 8px #00000029',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isXs ? 'space-evenly' : 'space-between',
        alignItems: 'center',
      }}>
      <Box>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={{ sm: 2, xs: 1 }}>
          <Grid item>
            <img
              src={icon ? icon : Icon}
              alt=""
              style={{ width: '50px', height: '50px', color: '#314D95' }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                font: {
                  sm: 'normal normal bold 20px/24px Trebuchet MS',
                  xs: 'normal normal bold 16px/20px Trebuchet MS',
                },
                color: '#314D95',
              }}>
              {title}
            </Typography>
          </Grid>
          {!isXs && (
            <Grid item>
              <Typography
                sx={{ font: 'normal normal normal 12px/16px Trebuchet MS', color: '#191D1E' }}>
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <a href={url}>
          <button
            style={{
              background: '#314D95 0% 0% no-repeat padding-box',
              borderRadius: '5px',
              color: '#ffffff',
              padding: '5px 20px',
              border: 'none',
              cursor: 'pointer',

              font: 'normal normal normal 14px/18px Trebuchet MS',
            }}>
            IR
          </button>
        </a>
      </Box>
    </Box>
  );
}

export default IconBox;

