import { unformatRut } from './format';
import { RUT } from './inputTypes';

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_TAX_NUMBER = 'VALIDATE_TAX_NUMBER';
export const VALIDATE_NUMBER = 'VALIDATE_NUMBER';
export const VALIDATE_PHONE = 'VALIDATE_PHONE';
export const REQUIRED = 'REQUIRED';

export const FIELD_TYPE = {
  RUT: 1,
  EMAIL: 2,
  OTHER: 3,
};

// tenga por lo menos 1 caracter
const validateRequired = (valor) => valor.length > 0;

export const validateEmail = (correo) => {
  const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return expresionRegular.test(correo);
};

// que sea un numero
export const validateNumber = (valor) => !Number.isNaN(valor);
// que sea un numero y que sea mayor a 8
const validatePhone = (valor) => !!(validateNumber(valor) && valor.length > 7);

const generateVerifierCode = (rut) => {
  let M = 0;
  let S = 1;
  // eslint-disable-next-line
  for (; rut; rut = Math.floor(rut / 10)) S = (S + (rut % 10) * (9 - (M++ % 6))) % 11;
  return S ? S - 1 : 'k';
};

// valida el digito verificador
const validateTaxtNumber = (rut) => {
  const rutCompleto = rut.replace(/[-.]/g, '').trim();
  if (!/^[0-9]+[0-9kK]{1}$/.test(rutCompleto)) return false;
  let digv = rutCompleto.slice(-1);
  const rutSinDv = rutCompleto.slice(0, -1);
  if (digv === 'K') digv = 'k';

  return generateVerifierCode(rutSinDv).toString() === digv.toString();
};

// aqui se valida un campo
export const validate = (value, validators) => {
  let isValid = true;
  validators.forEach((validator) => {
    switch (validator) {
      case VALIDATE_EMAIL:
        isValid = validateEmail(value);
        break;
      case VALIDATE_TAX_NUMBER:
        isValid = validateTaxtNumber(value);
        break;
      case VALIDATE_NUMBER:
        isValid = validateNumber(value);
        break;
      case VALIDATE_PHONE:
        isValid = validatePhone(value);
        break;
      case REQUIRED:
        isValid = validateRequired(value);
        break;
      default:
        // TODO: Fill default case
        break;
    }
  });
  return isValid;
};
export function isTaxNumber(text) {
  let emailTaxNumber = text;
  emailTaxNumber = unformatRut(text);
  const regexRut = /^[0-9]+k?$/i;
  const isRut = regexRut.test(emailTaxNumber);
  if (isRut) {
    return FIELD_TYPE.RUT;
  }
  return FIELD_TYPE.EMAIL;
}

export function taxNumberValidator(data) {
  let rut = data;
  // Formato del RUT: 12345678-9
  rut = rut.replace(/[^0-9kK]/g, ''); // Eliminar caracteres no numéricos ni la 'k' en mayúscula
  if (rut.length < 2) return false;

  let suma = 0;
  const caracteres = '1234567890kK';
  const serie = [3, 2, 7, 6, 5, 4, 3, 2];

  for (let i = 0; i < rut.length - 1; i++) {
    const char = rut.charAt(i);
    if (caracteres.indexOf(char) === -1) return false;

    suma += parseInt(char) * serie[i];
  }
  const resto = suma % 11;
  let dv = 11 - resto;
  if (dv === 11) dv = 0;
  if (dv === 10) dv = 'k';
  if (rut.charAt(rut.length - 1).toLowerCase() !== dv.toString().toLowerCase()) {
    return false;
  }
  return true;
}
export const isValidRedirectUrl = (url) => {
  try {
    const allowedDomains = ['tattersallremates.cl', 'tattersallpropiedades.cl', 'tattersallcampos.cl', 'tattesallap.cl']; // Lista blanca de dominios permitidos
    const parsedUrl = new URL(url);

    // Verifica que sea HTTPS y que el dominio esté en la lista blanca
    return (
      allowedDomains.some((domain) => parsedUrl.hostname.endsWith(domain))
    );
  } catch (e) {
    return false; // Si falla el parsing, la URL no es válida
  }
};
