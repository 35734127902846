import React, { useState } from 'react';

/* Material UI */
import { Grid, MenuItem, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

/* Extra */
import { useLocation } from 'react-router-dom';

/* Project */
import { SubmitForm } from 'commons/components/SubmitForm/SubmitForm';
import { basicUrlPagination, getUrlLotsParams } from 'utils/functions';

function SearchNav(props) {
  const { classes, searchControls, actions, history } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlParams = getUrlLotsParams(params);

  const [localControls, setControls] = useState({
    searchParaments: urlParams.searchParaments || searchControls.searchParaments || '',
    areaId: urlParams.areaId || searchControls.areaId || '',
  });

  const handleAreRedirect = (areaId) => {
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);
    const type = url.pathname.split('/')[1];
    if (type === 'auctions' || type === 'lots') {
      url.searchParams.set('areaId', areaId);
      const destinyUrl = url.pathname + url.search;
      history.push(destinyUrl);
    }
  };
  const handleControls = ({ target: { value, name } }) => {
    setControls({ ...localControls, [name]: value });
  };

  const cleanQuery = () => {
    setControls({ ...localControls, searchParaments: '' });
  };

  function onKeyDown(event) {
    if (
      localControls.areaId.toString() === '0' ||
      event.key !== 'Enter' ||
      localControls.searchParaments.trim() === searchControls.searchParaments.trim()
    ) {
      return;
    }

    actions.globalFiltersReset();
    actions.searchControlsChanged({
      searchParaments: localControls.searchParaments,
      areaId: localControls.areaId,
    });
    actions.controlsChanged({
      searchParaments: localControls.searchParaments,
      areaId: localControls.areaId,
    });
    // const pathname = props.history.location.pathname;
    history.push(
      `/lots?areaId=${localControls.areaId}&searchParaments=${localControls.searchParaments}${basicUrlPagination}`,
    );
    actions.controlsChanged({ forceReload: true });
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, width: '100%' }}
      component={SubmitForm}>
      <FormControl sx={{ minWidth: 150 }} variant="standard" className={classes.formControl}>
        <InputLabel id="areaId-label">Área</InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          labelId="areaId-label"
          name="areaId"
          value={localControls.areaId}
          onChange={handleControls}>
          <MenuItem value={2} onClick={() => handleAreRedirect(2)}>
            <HomeWorkOutlinedIcon style={{ height: '12px' }} color="primary" fontSize="inherit" />
            <span style={{ height: '15px', paddingLeft: '2px' }}>Propiedades</span>
          </MenuItem>
          <MenuItem value={1} onClick={() => handleAreRedirect(1)}>
            <LocalShippingOutlinedIcon
              style={{ height: '12px' }}
              color="primary"
              fontSize="inherit"
            />
            <span style={{ height: '15px', paddingLeft: '2px' }}>Bienes muebles</span>
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        sx={{ maxWidth: 550 }}
        label="Búsqueda"
        variant="standard"
        value={localControls.searchParaments}
        name="searchParaments"
        onChange={handleControls}
        placeholder="Busque aquí sus lotes de interés"
        fullWidth
        onKeyDown={onKeyDown}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disableRipple
                disableFocusRipple
                color="primary"
                className={classes.iconButton}
                aria-label="label-to-search"
                onClick={cleanQuery}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{ 'aria-label': 'naked' }}
      />
    </Box>
  );
}

export default SearchNav;
