import { Box, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Carousel from 'react-material-ui-carousel';
import { useFetchData } from '../hooks/useFetchData';
import fontStyles from '../styles/fontStyles';
import BoxGrid from './BoxGrid';

function Banner() {
  const state = useFetchData(`/api/banner-gdas?populate=*`);
  const { data, loading, error } = state;

  var fallbackItems = [
    {
      description: 'Probably the most random thing you have ever seen!',
    },
    {
      description: 'Hello World!',
    },
    {
      description: 'Hello World!',
    },
  ];

  const transformedData =
    data?.data?.map((item) => {
      return {
        name: item.attributes?.title || '',
        image: item.attributes?.image?.data?.[0]?.attributes?.url || '',
        imageMobile: item.attributes?.imageMobile?.data?.[0]?.attributes?.url || '',
        imageTablet: item.attributes?.imageTablet?.data?.[0]?.attributes?.url || '',
        url: item.attributes?.url || '',
      };
    }) || [];

  const items = loading || error ? fallbackItems : transformedData;

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}>
        <Carousel paddingTop={'40px'} indicators={false}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
        <Box
          sx={{
            position: 'absolute',
            top: '50%', // Centrado vertical
            left: '50%', // Centrado horizontal
            transform: 'translate(-50%, -50%)', // Ajusta el punto de referencia al centro
            zIndex: 2,
            width: '100%', // O ajusta el tamaño según tus necesidades
            display: 'flex', // Opcional: asegura el centrado de contenido interno
            justifyContent: 'center', // Opcional: centra contenido interno horizontalmente
            alignItems: 'center', // Opcional: centra contenido interno verticalmente
          }}>
          <BoxGrid></BoxGrid>
        </Box>
      </Box>
    </>
  );
}

function Item(props) {
  const isXs = useMediaQuery('(max-width:800px)');
  const isSm = useMediaQuery('(max-width:1300px)');
  const content = (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { lg: '500px', sm: '600px', xs: '600px' },
      }}>
      {/* Image */}
      <Box
        component="img"
        src={isXs ? props.item.imageMobile : isSm ? props.item.imageTablet : props.item.image}
        alt="My Image"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />

      {/* Text on Top */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#ffffff',
          textAlign: 'center',
          padding: '0 20px',
          width: '80%',
        }}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" component="h5" sx={fontStyles.loteTitle}>
              {props.item.name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
  return (
    <Grid item container xs={12} alignItems={'center'} justifyContent={'center'}>
      {props.item.url ? (
        <a
          href={props.item.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', width: '100%' }}>
          {content}
        </a>
      ) : (
        content
      )}
    </Grid>
  );
}

export default Banner;

