// @ts-nocheck
import React, { useState } from 'react';

/* Material UI */
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  useMediaQuery,
  Grid,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Hidden,
  Divider,
  FormControl,
  FormControlLabel,

} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import MenuIcon from '@mui/icons-material/Menu';

/* Router */
import { useHistory } from 'react-router-dom';

/* Project */
import tattersallLogo from 'utils/images/logo-tattersall.png';
import { falsy } from 'utils/functions';
import Paths from 'config/paths';
import navbarStyles from './navbar.styles';

const useStyles = makeStyles()(navbarStyles);

function NavBar(props) {
  const { SearchNav, actions } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { classes } = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  function handleRedirection(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    actions.globalFiltersReset();
    if (history.location.pathname === Paths.HOME) return;
    props.history.push(Paths.HOME);
  }

  function gotoProfile() {
    history.push(Paths.PROFILE);
  }

  const handleFAQClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    history.push(Paths.FAQ);
  };

  const existsUser = !falsy(props.user);



  const menuDialog = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => setAnchorEl(false)}
      onBlur={() => setAnchorEl(false)}
      disableScrollLock>
      {existsUser && isMobile && (
        <div>
          <MenuItem>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography style={{ fontWeight: 'bold', color: '#0d47a1' }}>
                {props.objects.people[props.user.personId] &&
                  props.objects.people[props.user.personId].fullName()}
              </Typography>
              <Typography style={{ color: 'inherit' }}>
                {props.user.roleId && props.objects.roles[props.user.roleId]?.name}
              </Typography>
            </div>
          </MenuItem>
          <Divider />
        </div>
      )}
      <MenuItem onClick={gotoProfile}>Mi perfil</MenuItem>
      <Divider />
      <MenuItem onClick={props.logOut}>
        <Typography color="error">Cerrar sesión</Typography>
      </MenuItem>
    </Menu>
  );

  const authSection = falsy(props.user) ? (
    <Grid item xs={12}>
      <Hidden mdDown>
        <Grid container justifyContent="flex-end" alignItems="center">
          {/* <Button
            className={classes.authShortcuts}
            onClick={() => props.history.push('/login')}
            color="inherit">
            INGRESA
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            className={classes.authShortcuts}
            onClick={() => props.history.push('/sign_up')}
            color="inherit">
            REGÍSTRATE
          </Button> */}
          <Button
            className={classes.authShortcuts}
            onClick={() => props.history.push('/login')}
            color="inherit">
            INGRESAR
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button
            className={classes.authShortcuts}
            component="a"
            href={Paths.FAQ}
            onClick={handleFAQClick}
            color="inherit">
            PREGUNTAS FRECUENTES
          </Button>
        </Grid>
      </Hidden>
    </Grid>
  ) : (
    <Grid item xs={12}>
      <ListItem style={{ float: 'right', width: 'auto', padding: '0px' }}>
        <Hidden mdDown>
          <ListItemText
            onClick={(e) => setAnchorEl(e.currentTarget)}
            style={{ cursor: 'pointer' }}
            primary={
              <Typography style={{ color: 'inherit' }}>
                {props.objects.people[props.user.personId] &&
                  props.objects.people[props.user.personId].fullName()}
              </Typography>
            }
            secondary={
              <Typography style={{ color: 'inherit', float: 'right' }}>
                {props.user.roleId && props.objects.roles[props.user.roleId]?.name}
              </Typography>
            }
          />
        </Hidden>
        <ListItemAvatar
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={{ cursor: 'pointer', marginLeft: 15 }}>
          <Avatar color="primary" style={{ backgroundColor: '#999999', color: 'white' }}>
            {props.objects.people[props.user.personId] &&
              props.objects.people[props.user.personId].fullName()[0]}
          </Avatar>
        </ListItemAvatar>
      </ListItem>
    </Grid>
  );

  return (
    <AppBar
      position="static"
      /*       style={{ height: isMobile ? '10px' : '10vh' }} */
      className={classes.navContainer}>
      <Toolbar className={classes.navBar} style={{ height: '100%' }}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={9} md={3} lg={3}>
            <IconButton
              onClick={props.toggleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              user={props.user}
              size="large">
              <MenuIcon fontSize="large" />
            </IconButton>
            {!props.sideBar && (
              <img
                onClick={handleRedirection}
                aria-hidden="true"
                src={tattersallLogo}
                style={{ height: '42px', cursor: 'pointer' }}
                alt="logo"
              />
            )}
            {props.sideBar && (
              <img
                aria-hidden="true"
                src={tattersallLogo}
                onClick={handleRedirection}
                style={{
                  maxHeight: '27px',
                  width: '20vh',
                  marginRight: 'auto',
                  marginLeft: '50px',
                  marginTop: '7px',
                  cursor: 'pointer',
                }}
                alt="logo"
              />
            )}
          </Grid>
          <Hidden lgDown>
            <Grid sx={{ pb: 2 }} item md={4} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
              {SearchNav || <div />}
            </Grid>
          </Hidden>
          <Grid item xs={6} lg={3} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <Grid container style={{ justifyContent: 'flex-end' }}>
              {menuDialog}
              {!props.sideBar && authSection}
            </Grid>
          </Grid>
          <Hidden lgUp>
            <Grid item xs={12} sm={12} sx={{ justifyContent: 'center', pb: 2 }}>
              {SearchNav || <div />}
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
