import { Box, Grid, Typography } from '@mui/material';
import fontStyles from '../styles/fontStyles';

function Lote(props) {
  const { title, text1, image, color, textColor, url, lotType } = props;

  return (
    <Grid item xs={3}>
      <a href={url} style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            cursor: 'pointer',
            position: 'relative', // Position the Box relative to the overlay and text
            width: '100%', // Full width of the container
            height: '400px',
            transition: 'transform 0.3s, filter 0.3s',
            '&:hover': {
              transform: 'scale(1.03)',
              zIndex: 100,
            },
            // Apply hover effect to both the overlay and text at the same time
            '&:hover .overlay': {
              opacity: 0, // Make the overlay fully transparent
            },
            '&:hover .text': {
              opacity: 0, // Make the text disappear
            },
          }}>
          {/* Image */}
          <Box
            component="img"
            src={image} // Use the imported image
            alt="My Image"
            sx={{
              width: '100%', // 100% width on all screen sizes
              height: '100%', // Match the height of the container
              objectFit: 'cover', // Ensure the image covers the area
            }}
          />
          {/* Color Overlay */}
          <Box
            className="overlay" // Add a class to target it in the hover
            sx={{
              position: 'absolute', // Position the overlay on top of the image
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: color, // Overlay color
              opacity: 0.7, // Initial transparency level
              transition: 'opacity 0.3s ease-in-out', // Smooth transition when hovering
            }}
          />

          {/* Text on Top */}
          <Box
            className="text" // Add a class to target it in the hover
            sx={{
              position: 'absolute', // Position the text on top of the overlay
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)', // Center the text
              color: textColor, // Text color
              textAlign: 'center', // Center align the text
              padding: '0 20px', // Optional: Add padding for better readability on smaller screens
              transition: 'opacity 0.3s ease-in-out', // Smooth transition for text visibility
              opacity: 1, // Initially, the text is fully visible
            }}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" sx={fontStyles.loteDescription}>
                  {lotType}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" sx={fontStyles.loteTitle}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="h6" sx={fontStyles.loteDescription}>
                  {text1}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </a>
    </Grid>
  );
}

export default Lote;

