import { Grid } from '@mui/material';
import APIcon from '../../../../commons/assets/icons/AP.svg';
import CamposIcon from '../../../../commons/assets/icons/Campos.svg';
import PropiedadesIcon from '../../../../commons/assets/icons/Propiedades.svg';
import RematesIcon from '../../../../commons/assets/icons/Remates.svg';
import IconBox from './IconBox';

function BoxGrid() {
  return (
    <Grid
      container
      direction={'row'}
      padding={2}
      paddingBottom={{ sm: 0, xs: 2 }}
      spacing={4}
      justifyContent="center" // Centra horizontalmente
      alignItems="center" // Centra verticalmente
    >
      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Asegura que los elementos internos se organicen en columna
        }}>
        <IconBox
          title={'PROPIEDADES'}
          description={
            'Remates, Licitaciones, Corretaje y Asesorías. Industriales, Comerciales y Habitacionales.'
          }
          url={process.env.REACT_APP_PROPIEDADES_URL}
          icon={PropiedadesIcon}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Asegura que los elementos internos se organicen en columna
        }}>
        <IconBox
          title={'CAMPOS'}
          description={'Remates, Licitaciones, Corretaje y Asesorías.'}
          url={process.env.REACT_APP_CAMPOS_URL}
          icon={CamposIcon}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Asegura que los elementos internos se organicen en columna
        }}>
        <IconBox
          title={'ADMINISTRACIÓN Y ARRIENDOS'}
          description={'Propiedades habitacionales sector oriente Santiago.'}
          url={process.env.REACT_APP_ARRIENDOS_URL}
          icon={APIcon}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column', // Asegura que los elementos internos se organicen en columna
        }}>
        <IconBox
          title={'MAQUINARIA / EQUIPOS VEHÍCULOS'}
          description={'Remates, Licitaciones y Ventas Directas.'}
          url={process.env.REACT_APP_REMATES_URL}
          icon={RematesIcon}
        />
      </Grid>
    </Grid>
  );
}

export default BoxGrid;

