import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Camion from '../../../../commons/assets/camion.jpg';

export default function CustomCard({ item }) {
  return (
    <Card
      sx={{
        width: 270,
        height: 420,
        transition: 'transform 0.3s, filter 0.3s',
        '&:hover': {
          transform: 'scale(1.03)',
          zIndex: 100,
        },
      }}>
      <Grid container item justifyContent={'center'} paddingTop={3}>
        <CardMedia
          component="img"
          image={item?.document ? `${item?.document}` : Camion}
          sx={{ width: '85%', height: '150px' }}
        />
      </Grid>

      <CardContent>
        <Box sx={{ height: 170 }}>
          <Typography
            style={{
              color: '#304e95',
              fontStyle: 'normal', // equivalent to 'normal' in the shorthand
              fontWeight: 'bold', // equivalent to 'bold' in the shorthand
              fontSize: '22px', // equivalent to '20px' in the shorthand
              lineHeight: '22px', // equivalent to '30px' in the shorthand
              fontFamily: 'Trebuchet MS',
            }}>
            {item?.date}
          </Typography>
          <Typography
            style={{
              color: '#191D1E',
              fontStyle: 'normal', // equivalent to 'normal' in the shorthand
              fontWeight: 'normal', // equivalent to 'bold' in the shorthand
              fontSize: '14px', // equivalent to '20px' in the shorthand
              lineHeight: '18px', // equivalent to '30px' in the shorthand
              fontFamily: 'Trebuchet MS',
            }}>
            {item?.name}
            <br />
            {item?.auctionType}
            <br />
            {item?.hour}
            <br />
            {item?.modality}
          </Typography>
        </Box>
        <Box
          sx={{
            paddingY: 1,
          }}>
          <a
            href={`https://tattersallgda.cl/lots?areaId=${item?.areaId}&auctionId=${item?.idAuction}&offset=0&page=0&limit=12&unitId=0&minRange=0&maxRange=0`}>
            <button
              style={{
                backgroundColor: '#314D95',
                padding: '5px 20px',
                borderRadius: '7px',
                width: '175px',
                height: '38px',
                boxShadow: '0px 3px 6px #00000029',
                border: 'none',
                cursor: 'pointer',
              }}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  color: '#ffffff',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Trebuchet MS',
                }}>
                VER REMATE
              </Typography>
            </button>
          </a>
        </Box>
      </CardContent>
    </Card>
  );
}

