/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react';
import logo from 'utils/images/logo-tattersall.png';
import { Button, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import useApi from 'utils/hooks/useApi';
import { history } from 'utils/history';
import { useDispatch } from 'react-redux';
import StyledBox from 'utils/styledComponent/styledBox';

import TextFieldForm from 'utils/formComponents/textField';
import { useForm } from 'react-hook-form';
import { LOGIN } from '../reducer/login.reducer';
import VIEW from '../const/view';
import { isValidRedirectUrl } from 'utils/validators';
// Función para formatear el tiempo en minutos y segundos
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}
export default function Activate(props) {
  const { defaultEmail } = props;
  const { control, handleSubmit } = useForm();
  const [email, setEmail] = useState('');
  const [taxNumber, setTaxnumber] = useState('');
  const location = useLocation();
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { fetchData } = useApi();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.get('redirectUrl');
  const redirectDomain = urlParams.get('redirectDomain');
  let intervalId;

  useEffect(() => {
    // Si el contador llega a cero, limpiar el intervalo
    if (count <= 0) {
      clearTimeout(intervalId);
      return;
    }

    // Configurar el intervalo
    intervalId = setTimeout(() => {
      setCount((currentCount) => currentCount - 1);
    }, 1000);

    // Limpiar el intervalo cuando el componente se desmonta
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(intervalId);
  }, [count]);

  useEffect(() => {
    setEmail('');
    setTaxnumber('');

    if (!location.state && !defaultEmail) {
      history.push(`${VIEW.LOGIN}`);
      return;
    }
    let previusPath;
    let type;
    let value;
    let locationEmail;
    let locationTaxNumber;

    if (defaultEmail) {
      previusPath = '';
      type = '';
      value = defaultEmail;
      locationEmail = defaultEmail;
      locationTaxNumber = '';
    } else if (location?.state) {
      previusPath = location.state.previusPath || '';
      (type = location.state.type), (value = location.state.value);
      locationEmail = location.state.registerEmail;
      locationTaxNumber = location.state.registerTaxNumber;
    }
    const getTtl = async () => {
      let params = '';
      if (value) {
        if (type === 'taxNumber') {
          params = `rut=${value}`;
        } else {
          params = `email=${value}`;
        }
      } else {
        params = `rut=${locationTaxNumber || ''}&email=${locationEmail || ''}`;
      }
      const res = await fetchData({
        url: `api/v1/users/getTtl?${params}`,
      });
      setCount(res.ttl <= 0 ? 0 : res.ttl);
    };
    getTtl();

    if (previusPath === VIEW.REGISTER) {
      setEmail(locationEmail);
      setTaxnumber(locationTaxNumber);
      return;
    }
    if (type === 'taxNumber') {
      setTaxnumber(value);
      return;
    }

    setEmail(value);
  }, []);

  const onSubmit = async (data) => {
    setError('');
    let response;
    try {
      response = await fetchData({
        url: `api/v1/users/verifyCode?email=${email}&rut=${taxNumber}&verifyCode=${data.code}`,
      });
      if (response && response.success) {
        localStorage.setItem('user', JSON.stringify({ token: response.data.token }));
        dispatch({ type: LOGIN.LOGIN_USER_SUCCESS, payload: response.data });
        if (location.state?.to) {
          return history.push(location.state?.to);
        }
        if (redirectUrl) {
          return history.push(redirectUrl);
        }
        if (redirectDomain) {
          if (isValidRedirectUrl(redirectDomain)) {
            return window.location.href = `${redirectDomain}?param=${response.data.token}`
          }
        }
        history.push('/');
      } else {
        setError(
          response
            ? response.message
            : 'Lo sentimos, parece que ocurrió un error en el servidor. Por favor, ponte en contacto con nuestro equipo de soporte para obtener ayuda. Lamentamos las molestias que esto pueda causarte.',
        );
      }
    } catch (e) {
      setError(
        response
          ? response.message
          : 'Lo sentimos, parece que ocurrió un error en el servidor. Por favor, ponte en contacto con nuestro equipo de soporte para obtener ayuda. Lamentamos las molestias que esto pueda causarte.',
      );
    }
  };
  const sendCodeAgain = async () => {
    setError('');
    let response;
    try {
      response = await fetchData({
        url: `api/v1/users/send_verifyCode?email=${email}&rut=${taxNumber}`,
      });
      setMessage(response.message);
    } catch (e) {
      setError(e.message);
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        background:
          'linear-gradient(0deg, rgba(7,42,104,1) 0%, rgba(0,23,64,1) 100%, rgba(32,75,154,1) 100%)',
      }}>
      <div
        style={{
          flex: 1,

          backgroundSize: 'contained',
          backgroundPositionX: '50%',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <StyledBox sx={{ maxWidth: 400 }}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: 4,
            }}>
            <img
              src={logo}
              style={{
                width: '80%', // ajusta el ancho de la imagen al 80% del contenedor
                height: 'auto', // altura automática para mantener la proporción original
              }}
              alt="Texto alternativo"
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', paddingTop: 2 }}>
            <Typography sx={{ fontSize: 15, color: '#5D6871' }}>
              {message || 'Se acaba de enviar un código a tu correo'}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <TextFieldForm
              key="txt-code"
              control={control}
              id="txt-code"
              label="Código"
              name="code"
              type="text"
              rules={{ required: 'Este campo es obligatorio.' }}
              helperText="Por favor, ingresa el código que se encuentra en tu correo electrónico."
              width="100%"
            />
            <Typography sx={{ fontSize: 15, color: '#5D6871' }}>{formatTime(count)}</Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 2,
              flexDirection: 'column',
            }}>
            <Typography sx={{ fontSize: 15, color: '#e11', fontWeight: 'bold' }}>
              {error}
            </Typography>
            <Typography
              onClick={() => {
                sendCodeAgain();
                setCount(600);
              }}
              sx={{ color: '#0D47A1', fontSize: 15, cursor: 'pointer', marginTop: 2 }}>
              ¿No te llegó? Reenviar
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 2,
            }}>
            <Button
              disabled={count <= 0}
              sx={{
                backgroundColor: count <= 0 ? '#BFC9CA' : '#093170',
                width: '100%',
                height: '55%',
              }}
              onClick={handleSubmit(onSubmit)}>
              <Typography style={{ color: '#fff', fontSize: 20 }}>SIGUIENTE</Typography>
            </Button>
          </Box>
        </StyledBox>
      </div>
    </div>
  );
}
