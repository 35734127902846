import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, CircularProgress, IconButton, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { useEffect, useMemo, useState } from 'react';
import { useFetchData } from '../hooks/useFetchData';
import CustomCard from './CustomCard';

function Carousel() {
  const isXs = useMediaQuery('(max-width:800px)');
  const isSm = useMediaQuery('(max-width:1300px)'); // Screen size sm to md

  const state = useFetchData(`/api/finalized-auctions?populate=image`);
  const { data, isLoading: loading, error } = state;

  const transformedData =
    data?.data?.map((item) => {
      return {
        id: item.id || '',
        image: item.attributes?.image?.data?.[0]?.attributes?.url || '',
      };
    }) || [];

  const stateData = useFetchData(`/api/upcoming-auctions-all`);
  const { data: dataEndpoint } = stateData;

  const getOffsetDirection = () => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = offsetInMinutes / 60;

    if (offsetInMinutes > 0) {
      console.log(`Offset positivo: UTC-${Math.abs(offsetInHours)}`);
      return -offsetInHours; // Para reflejar que está detrás de UTC
    } else if (offsetInMinutes < 0) {
      console.log(`Offset negativo: UTC+${Math.abs(offsetInHours)}`);
      return -offsetInHours; // Para reflejar que está adelante de UTC
    } else {
      console.log('No hay offset: UTC±0');
      return 0; // Sin offset
    }
  };

  // Ejemplo de uso
  const timezoneOffset = getOffsetDirection();

  const formatDate = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);

    // Ajusta la hora aplicando el offset
    dateObj.setHours(dateObj.getHours() + timezoneOffset);

    const day = dateObj.getUTCDate();
    const month = dateObj.toLocaleString('es-ES', { month: 'long' });

    // Capitaliza la primera letra del mes
    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

    return {
      date: `${day} de ${capitalizedMonth}`,
      hour: `${hours}:${minutes}`,
    };
  };

  const transformedDataEndpoint = Array.isArray(dataEndpoint)
    ? dataEndpoint?.map((item) => {
        const { date: formattedDate, hour: formattedHour } = formatDate(item.date);

        return {
          id: item.id || '',
          idAuction: item.idAuction || '',
          name: item.name || '',
          date: formattedDate || '',
          hour: formattedHour || '',
          auctionType: item.auctionType || '',
          modality: item.modality || '',
          document: item?.document || '',
          areaId: item?.areaId || '1',
        };
      }) || []
    : [];

  const imageMap = transformedData.reduce((acc, item) => {
    if (item.id) {
      acc[item.id] = item.image; // Usa el `id` como clave y almacena la imagen
    }
    return acc;
  }, {});

  // Merge the two datasets based on index
  const mergedData = useMemo(
    () =>
      transformedDataEndpoint.map((item) => {
        return {
          ...item, // Contiene `id`, `name`, `date`, `auctionType`, `hour`, etc.
          image: imageMap[item.id] || '', // Agrega la imagen correspondiente al `id` o un string vacío si no existe
        };
      }),
    [data, dataEndpoint],
  );

  console.log(mergedData);

  // setting the state variables
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left');

  // Dynamically set the cardsPerPage based on screen size
  const cardsPerPage = isXs ? 1 : isSm ? 2 : 4; // 1 card for xs, 2 cards for sm, 3 cards for larger screens

  // Create cardArray with the merged data
  const cardArray = useMemo(() => {
    return mergedData.map((item, i) => <CustomCard key={i} item={item} />);
  }, [mergedData]);

  useEffect(() => {
    if (mergedData.length > 0) {
      setCards(cardArray);
    }
  }, [mergedData, cardArray]);

  const containerWidth = `${100 / cardsPerPage}%`; // Divide the container width equally among the number of cards

  const totalPages = Math.ceil((cards.length || 0) / cardsPerPage);

  const handlePageChange = (targetPage) => {
    const direction = targetPage > currentPage ? 'left' : 'right';
    setSlideDirection(direction);
    setCurrentPage(() => Math.max(0, Math.min(targetPage, totalPages - 1))); // Ensure within bounds
  };

  if (error) {
    return <Typography color="error">Error loading data: {error?.message}</Typography>;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '400px',
          width: '100%',
          marginTop: '10px',
          position: 'relative', // Added to contain loader
        }}>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background to dim content
              zIndex: 10,
            }}>
            <CircularProgress style={{ color: '#304e95' }} />
          </Box>
        )}
        <IconButton
          onClick={() => handlePageChange(currentPage - 1)}
          sx={{ margin: 0 }}
          disabled={currentPage === 0 || loading}>
          <NavigateBeforeIcon
            sx={{
              background: '#ffffff',
              borderRadius: '50%',
              color: '#304e95',
            }}
          />
        </IconButton>
        <Box sx={{ width: `${containerWidth}px`, height: '100%' }}>
          {cardArray.map((card, index) => (
            <Box
              key={`card-${index}`}
              sx={{
                width: '100%',
                height: '100%',
                display: currentPage === index ? 'block' : 'none',
              }}>
              <Slide direction={slideDirection} in={currentPage === index}>
                <Stack
                  spacing={4}
                  direction="row"
                  alignContent="center"
                  justifyContent="center"
                  sx={{ width: '100%', height: '100%' }}>
                  {cards.slice(index * cardsPerPage, index * cardsPerPage + cardsPerPage)}
                </Stack>
              </Slide>
            </Box>
          ))}
        </Box>
        <IconButton
          onClick={() => handlePageChange(currentPage + 1)}
          sx={{ margin: 0 }}
          disabled={currentPage >= Math.ceil((cards.length || 0) / cardsPerPage) - 1 || loading}>
          <NavigateNextIcon
            sx={{
              background: '#ffffff',
              borderRadius: '50%',
              color: '#304e95',
            }}
          />
        </IconButton>
      </Box>
      {/* Page Indicator Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          position: 'relative',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1,
          paddingTop: 2,
        }}>
        <Stack direction="row" spacing={1} sx={{ marginX: 2, paddingTop: 4 }}>
          {Array.from({ length: totalPages }).map((_, pageIndex) => (
            <Button
              key={`page-${pageIndex}`}
              onClick={() => handlePageChange(pageIndex)} // Pass specific page index
              variant={pageIndex === currentPage ? 'contained' : 'outlined'}
              sx={{
                minWidth: '15px',
                height: '15px',
                borderRadius: '50%',
                border: 'none',
                padding: 0,
                backgroundColor: pageIndex === currentPage ? '#304e95' : '#00000029',
                color: pageIndex === currentPage ? '#ffffff' : '#304e95',
                fontSize: '3px',
              }}></Button>
          ))}
        </Stack>
      </Box>
    </>
  );
}

export default Carousel;

