import React from 'react';

import Home from 'modules/main/Home/Home';
// eslint-disable-next-line spaced-comment
//import Login from 'modules/main/Login/Login';

import Activate from 'modules/main/Login2/views/Activate';
import FindUserForm from 'modules/main/Login2/views/FindUserForm';
import LoginForm from 'modules/main/Login2/views/LoginForm';
import RegisterForm from 'modules/main/Login2/views/RegisterForm';

import PageNotFound from 'modules/main/PageNotFound/PageNotFound';
import Register from 'modules/main/Register/Register';
import ResetPassword from 'modules/main/ResetPassword/ResetPassword';
import { Roles } from 'utils/functions';

import NewHome from 'modules/main/NewHome/NewHome';

import Paths from './paths';

const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('lazy-refreshed') || 'false');

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('lazy-refreshed', 'true');
          return window.location.reload();
        }

        return reject(error);
      });
  });

const Routes = {
  general: {
    [Paths.LOGIN]: { component: LoginForm },
    [Paths.FIND_USER]: { component: FindUserForm },
    [Paths.ACTIVATE]: { component: Activate },
    [Paths.REGISTER_USER]: { component: RegisterForm },
    [Paths.REGISTER]: { component: Register },
    [Paths.RESET_PASSWORD]: { component: ResetPassword },
    [Paths.DIRECT_SALE]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/direct-sale/DirectSale/DirectSale')),
      ),
    },
    [Paths.TRANSFERS_LOGIN]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/TransferManagerLogin/TransferManagerLogin')),
      ),
    },
    [Paths.DIRECT_SALE_LOTS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/direct-sale/DirectSaleLots/DirectSaleLots')),
      ),
    },
    [Paths.MANDATES_LOGIN]: {
      component: React.lazy(() =>
        lazyRetry(() => import('../modules/mandates-tracking/Login/MandatesLogin')),
      ),
    },
  },
  new_public: {
    [Paths.NEW_HOME]: { component: NewHome },
  },
  public: {
    [Paths.HOME]: { component: Home },
    [Paths.LOTS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/Lots/Lots'))),
      requireSearchParams: ['areaId'],
    },
    [Paths.AUCTIONS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/Auctions/Auctions'))),
      requireSearchParams: ['areaId', 'auctionCode'],
    },
    [Paths.LOT_DETAILS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/LotDetails/LotDetails'))),
    },
    [Paths.FAQ]: { component: React.lazy(() => import('modules/main/FAQ/FAQ')) },
    [Paths.FINISHED_AUCTIONS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/FinishedAuctions/FinishedAuctions')),
      ),
    },
/*     [Paths.GUARANTEE_MANAGERV2]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManagerv2/GuaranteeManager')),
      ),
      permissionPath: 'guaranteeManager',
      areaID: 'all',
    }, */
    // Guarantee Goods Steps
    [Paths.GUARANTEE_GOODS_STEP1]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManagerv2/components/StepOne/StepOneGoods')),
      ),
    },
    [Paths.GUARANTEE_GOODS_STEP2]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/StepTwoFurniture/StepTwoFurniture'),
        ),
      ),
    },
    [Paths.GUARANTEE_GOODS_STEP2_TENDER]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import(
            'modules/main/GuaranteeManagerv2/components/StepTwoFurniture/StepTwoFurnitureTender'
          ),
        ),
      ),
    },
    [Paths.GUARANTEE_GOODS_STEP3]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/BasesConfirmation/stepThreeGoods'),
        ),
      ),
    },
    // Guarantee Props Steps
    [Paths.GUARANTEE_PROP_STEP1]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManagerv2/components/StepOne/StepOneProp')),
      ),
    },
    [Paths.GUARANTEE_PROP_STEP2]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/StepTwoProperties/StepTwoProps'),
        ),
      ),
    },
    [Paths.GUARANTEE_PROP_STEP3]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/BasesConfirmation/stepThreeProps'),
        ),
      ),
    },
  },
  private: {
    [Paths.PAYMENTS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/Payments/Payments'))),
      permissionPath: 'payment',
      areaID: 'all',
    },
    [Paths.PROFILE]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/UserProfile/UserProfile'))),
      permissionPath: 'profile',
      areaID: 'all',
    },
    [Paths.GUARANTEES]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/Guarantees/Guarantees'))),
      permissionPath: 'guarantee',
      areaID: 'all',
    },
    [Paths.GUARANTEES_AND_PAYMENTS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteesAndPayments/GuaranteesAndPayments')),
      ),
      auth: Roles.FINANCIAL,
      permissionPath: 'guaranteeAndPayment',
      areaID: 'all',
    },
    [Paths.NEW_PAYMENT]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/NewPayment/NewPayment'))),
      permissionPath: 'newPayment',
      areaID: 'all',
    },
    [Paths.GUARANTEE_PROP_STEP4]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManagerv2/components/StepFour/StepFour')),
      ),
      permissionPath: 'guarantee',
      areaID: 'all',
    },
    [Paths.GUARANTEE_GOODS_STEP4]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/StepFour/StepFourGoods'),
        ),
      ),
      permissionPath: 'guarantee',
      areaID: 'all',
    },
    [Paths.FINISH_PAYMENT_GOODS]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/GuaranteeManagerv2/components/FinishPayment/index_goods'),
        ),
      ),
      permissionPath: 'guarantee',
      areaID: 'all',
    },
    [Paths.FINISH_PAYMENT]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManagerv2/components/FinishPayment/index')),
      ),
      permissionPath: 'guarantee',
      areaID: 'all',
    },
    [Paths.WEBPAY_FINAL]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/WebpayFinal/WebpayFinal'))),
      permissionPath: 'webpay',
      areaID: 'all',
    },
    [Paths.GUARANTEE_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/GuaranteeManager/GuaranteeManager')),
      ),
      permissionPath: 'guaranteeManager',
      areaID: 'all',
    },
    [Paths.USERS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/Users/Users'))),
      auth: Roles.FINANCIAL,
      permissionPath: 'user',
      areaID: 'all',
    },
    [Paths.EDIT_USER]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/EditUser/EditUser'))),
      auth: Roles.FINANCIAL,
      permissionPath: 'userEdition',
      areaID: 'all',
    },
    [Paths.SORT_LOTS]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/SortLots/SortLots'))),
      auth: Roles.MANAGEMENT,
      permissionPath: 'orderLot',
      areaID: 'all',
    },
    [Paths.ENTITLED_LOTS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/EntitledLots/EntitledLots')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'orderLot',
      areaID: 'all',
    },
    [Paths.LOTS_MANAGER]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/LotsManager/LotsManager'))),
      auth: Roles.MANAGEMENT,
      permissionPath: 'lotManager',
      areaID: 'all',
    },
    [Paths.LOT_CATEGORIES]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/LotCategories/LotCategories')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'maintainer',
      areaID: 'all',
    },
    [Paths.AUCTIONS_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/AuctionsManager/AuctionManager')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'auctionManager',
      areaID: 'all',
    },
    [Paths.AUCTION_CATEGORIES]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/AuctionCategories/AuctionCategories')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'maintainer',
      areaID: 'all',
    },
    [Paths.CONTACT_MESSAGES]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/ContactMessages/ContactMessages')),
      ),
      auth: Roles.CONTACT,
      permissionPath: 'contactMessage',
      areaID: 'all',
    },
    [Paths.SELLER_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/SellersManager/SellersManager')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'maintainer',
      areaID: 'all',
    },
    [Paths.VIEW_REPORT]: {
      component: React.lazy(() => lazyRetry(() => import('modules/main/ViewReport/ViewReport'))),
      auth: Roles.MANAGEMENT,
      permissionPath: 'viewReport',
      areaID: 'all',
    },
    [Paths.FEATURED_LOTS_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/FeaturedLotsManager/FeaturedLotsManager')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'featuredLotsManager',
      areaID: 1,
    },
    [Paths.CONTACTS_MANAGER]: {
      component: React.lazy(() =>
        import('modules/direct-sale/DirectSaleContactsManager/DirectSaleContactsManager'),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'contactsManager',
      areaID: 1,
    },
    [Paths.BRANDS_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/BrandsManager/BrandsManager')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'brandsManager',
      areaID: 1,
    },
    [Paths.MODELS_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/direct-sale/DirectSaleModelsManager/DirectSaleModelsManager'),
        ),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'modelsManager',
      areaID: 1,
    },
    [Paths.VEHICLE_TYPES_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/VehicleTypesManager/VehicleTypesManager')),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'vehicleTypes',
      areaID: 1,
    },
    [Paths.INSURANCE_INSTITUTIONS_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/main/InsuranceInstitutionsManager/InsuranceInstitutionsManager'),
        ),
      ),
      auth: Roles.MANAGEMENT,
      permissionPath: 'insuranceInstitution',
      areaID: 1,
    },
    [Paths.ADJUDICATIONS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/Adjudications/Adjudications')),
      ),
      permissionPath: 'adjudications',
      areaID: 'all',
    },
    [Paths.CURRENCY_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/CurrencyManager/CurrencyManager')),
      ),
      permissionPath: 'currencyManager',
      areaID: 1,
    },
    [Paths.SYSTEM_CHANGES]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/SystemChanges/SystemChanges')),
      ),
      permissionPath: 'systemChanges',
      areaID: 'all',
    },
    [Paths.POSTURE_MANAGER]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/main/PosturesManager/PosturesManager')),
      ),
      permissionPath: 'postureManager',
      areaID: 'all',
    },
  },
  transfers: {
    [Paths.TRANSFERS_LOTS]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/Transfers/Transfers')),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_LOTS_DETAILS]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/transferences/TransferManagerLotDetails/TransferManagerLotDetails'),
        ),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_RECORD_CERTIFICATE]: {
      component: React.lazy(() =>
        lazyRetry(() =>
          import('modules/transferences/RecordCertificateForm/RecordCertificateForm'),
        ),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_MANDATE_PREVIEW]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/MandatePreview/MandatePreview')),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_WORKSHEET]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/WorkSheetForm/WorkSheetForm')),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_FORM_TWENTY_THREE]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/FormTwentyThree/FormTwentyThree')),
      ),
      permissionPath: 'transfers',
    },
    [Paths.TRANSFERS_PENDING]: {
      component: React.lazy(() =>
        lazyRetry(() => import('modules/transferences/PendingTransferences/PendingTransferences')),
      ),
      permissionPath: 'transfers',
    },
  },
  mandates: {
    [Paths.MANDATES_HOME]: {
      component: React.lazy(() =>
        lazyRetry(() => import('../modules/mandates-tracking/LotsList/LotsList')),
      ),
      permissionPath: 'mandates',
    },
    [Paths.MANDATES_BULK_LOAD]: {
      component: React.lazy(() =>
        lazyRetry(() => import('../modules/mandates-tracking/BulkLoad/BulkLoad')),
      ),
      permissionPath: 'mandatesBulkLoad',
    },
  },
  others: {
    [Paths.PAGE_NOT_FOUND]: { component: PageNotFound },
  },
};

export default Routes;

