import { Box, Divider, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import GDABlue from '../../../../commons/assets/icons/GDABlue.svg';
import { useFetchData } from '../hooks/useFetchData';
import fontStyles from '../styles/fontStyles';
import Carousel from './Carousel';

function NextAuction() {
  const state = useFetchData(`/api/tutorial-videos-gda`);
  const { data } = state;

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#D9E0EA',
          padding: { xs: 0, sm: 4 },
          paddingX: { xs: 0, sm: 16 },
          textAlign: { xs: 'center', sm: 'start' },
        }}>
        <Typography
          variant="h5"
          component="h5"
          sx={fontStyles.nextAuctionTitle}
          paddingBottom={0}
          paddingTop={4}>
          PARTICIPA EN EL PRÓXIMO
        </Typography>
        <Typography variant="h5" component="h5" sx={fontStyles.nextAuctionTitle2}>
          REMATE
        </Typography>
        <Typography
          variant="h5"
          component="h5"
          sx={fontStyles.nextAuctionDescription}
          paddingBottom={2}>
          Te enseñamos como paso a paso
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: { xs: 'column', sm: 'row' },
          backgroundColor: '#D9E0EA',
        }}>
        <Box
          sx={{
            display: 'flex', // Habilita Flexbox
            justifyContent: 'center', // Centra horizontalmente
            alignItems: 'center',
            width: { sm: '45%', xs: '100%' },
            height: '280px',
            gap: 2,
            flexDirection: 'column',
          }}>
          <ReactPlayer url={data?.data?.attributes?.urlPropertiesVideo} width="80%" height="100%" />
          <button
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 3px 6px #00000029',
              borderRadius: '5px',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
            }}>
            <Typography variant="h5" component="h5" sx={fontStyles.nextAuctionButton}>
              PROPIEDADES Y CAMPOS
            </Typography>
          </button>
        </Box>
        <Box
          sx={{
            display: 'flex', // Habilita Flexbox
            justifyContent: 'center', // Centra horizontalmente
            alignItems: 'center',
            width: { sm: '45%', xs: '100%' },
            height: '280px',
            gap: 2,
            flexDirection: 'column',
          }}>
          <ReactPlayer url={data?.data?.attributes?.urlRentingVideo} width="80%" height="100%" />
          <button
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 3px 6px #00000029',
              borderRadius: '5px',
              padding: '10px 20px',
              border: 'none',
              cursor: 'pointer',
            }}>
            <Typography variant="h5" component="h5" sx={fontStyles.nextAuctionButton}>
              MAQUINARIA / EQUIPOS / VEHÍCULOS
            </Typography>
          </button>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: '#D9E0EA', paddingY: 4, display: 'flex', justifyContent: 'center' }}>
        <Divider
          sx={{
            color: '#314D95',
            border: '1.5px solid',
            opacity: 1,
            width: '90%',
          }}
        />
      </Box>
      <Box sx={{ backgroundColor: '#D9E0EA', paddingY: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 4,
          }}>
          <img
            src={GDABlue}
            alt="GDA Icon"
            style={{
              width: '40px',
              height: '40px',
            }}
          />
          <Typography
            variant="h4"
            component="h4"
            style={{
              color: '#304e95',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '30px',
              lineHeight: '35px',
              fontFamily: 'Trebuchet MS',
              textAlign: 'center',
              marginBottom: '20px',
            }}>
            PRÓXIMOS REMATES
          </Typography>
        </Box>

        <Carousel></Carousel>
      </Box>
    </>
  );
}

export default NextAuction;

