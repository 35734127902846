import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import { usePostData } from '../hooks/usePostData';
import fontStyles from '../styles/fontStyles';

const ContactModal = ({ open, handleClose }) => {
  const [formData, setFormData] = React.useState({
    productType: '',
    name: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',

    areaId: 1,
    typePerson: null,
    rut: '',
    company: '',
    lotCategoryId: null,
    loading: false,
    typeBusiness: null,
    typeInterest: null,
    typeChannel: [],

    optIn: true,
    lotId: null,
  });

  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const hasContent = (field) => formData[field] !== '';

  const { postData, isLoading, data } = usePostData(`/public_contact_messages/`);

  const handleClick = async () => {
    try {
      await postData(formData); // Pass formData here
    } catch (error) {
      console.error('Error posting data:', error.message);
      // You can also set a local error message here to display in the UI
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '16px',
        },
      }}>
      {data ? (
        <Grid container justifyContent="center" alignItems="center" direction={'column'}>
          <Grid item padding={4}>
            <CheckCircleOutlineIcon sx={{ color: '#039100', width: '120px', height: '120px' }} />
          </Grid>
          <Grid>
            <p
              style={{
                color: '#191D1E',
                fontSize: '18px',
                lineHeight: '22px',
                fontFamily: 'Trebuchet MS',
                marginBottom: '5px',
              }}>
              Mensaje de contacto recibido, nos contactaremos contigo pronto.
            </p>
          </Grid>
          <Grid item container xs={12} justifyContent={'center'} paddingY={2}>
            <button
              style={{
                backgroundColor: '#304e95',
                padding: '10px 20px',
                width: 300,
                height: '50px',
                textAlign: 'center',
                boxShadow: '0px 3px 6px #00000029',
                borderRadius: '7px',
                display: 'flex', // Use flexbox
                justifyContent: 'center', // Center content horizontally
                alignItems: 'center',
              }}
              onClick={handleClose}>
              <Grid container item alignItems={'center'} justifyContent={'center'}>
                <h5 style={fontStyles.buttonWhite}>Cerrar</h5>
              </Grid>
            </button>
          </Grid>
        </Grid>
      ) : (
        <>
          <DialogTitle>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center" // Align items vertically in the center
              direction="column"
              paddingTop={3}>
              <Grid item>
                <h4
                  style={{
                    color: '#304e95',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '28px',
                    lineHeight: '28px',
                    fontFamily: 'Trebuchet MS',
                    textAlign: 'center', // Center text within the element
                  }}>
                  CONTÁCTANOS
                </h4>
              </Grid>
              <Grid item container justifyContent="center">
                <Divider
                  sx={{
                    color: '#314D95',
                    border: '1.5px solid',
                    opacity: 1,
                    width: '60%',
                  }}
                />
              </Grid>
            </Grid>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {isLoading ? (
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress style={{ color: '#304e95' }} />
              </Grid>
            ) : (
              <Grid container xs={12} paddingX={3}>
                {/* First Name and Last Name Fields */}
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    margin="normal"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#9c9c9d',
                        },
                        '&:hover fieldset': {
                          borderColor: '#304e95', // Color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#304e95', // Your desired focus color
                          borderColor: '#304e95', // Focused color
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#304e95', // Change label color when focused
                        color: hasContent('name') ? '#304e95' : '#FF5733',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#9c9c9d', // Default color
                        '&.Mui-focused': {
                          color: '#304e95', // Color when focused
                        },
                      },
                    }}>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        textAlign: 'left',
                        position: 'absolute',
                        left: 0,
                        fontFamily: 'Trebuchet MS',
                        color: '#304e95',
                      }}>
                      Tipo de Producto
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={''}
                      name="areaId"
                      label="Tipo de Producto"
                      onChange={handleChange}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: hasContent('areaId') ? '#304e95' : '#FF5733',
                        },
                      }}>
                      <MenuItem value="1">Bienes</MenuItem>
                      <MenuItem value="2">Propiedades</MenuItem>
                      <MenuItem value="3">Campos</MenuItem>
                      <MenuItem value="4">Arriendos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item container xs={12} direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9c9c9d',
                          },
                          '&:hover fieldset': {
                            borderColor: '#304e95', // Color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#304e95', // Focused color
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: hasContent('name') ? '#304e95' : '#FF5733',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#9c9c9d', // Default color
                          '&.Mui-focused': {
                            color: '#304e95', // Color when focused
                          },
                        },
                      }}
                      name="name"
                      label="Nombre"
                      fullWidth
                      margin="normal"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9c9c9d',
                          },
                          '&:hover fieldset': {
                            borderColor: '#304e95', // Color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#304e95', // Focused color
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: hasContent('name') ? '#304e95' : '#FF5733',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#9c9c9d', // Default color
                          '&.Mui-focused': {
                            color: '#304e95', // Color when focused
                          },
                        },
                      }}
                      name="lastName"
                      label="Apellido"
                      fullWidth
                      margin="normal"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                {/* Phone and Email Fields */}
                <Grid item container xs={12} direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9c9c9d',
                          },
                          '&:hover fieldset': {
                            borderColor: '#304e95', // Color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#304e95', // Focused color
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: hasContent('name') ? '#304e95' : '#FF5733',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#9c9c9d', // Default color
                          '&.Mui-focused': {
                            color: '#304e95', // Color when focused
                          },
                        },
                      }}
                      name="phone"
                      label="Teléfono"
                      fullWidth
                      margin="normal"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#9c9c9d',
                          },
                          '&:hover fieldset': {
                            borderColor: '#304e95', // Color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#304e95', // Focused color
                          },
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: hasContent('name') ? '#304e95' : '#FF5733',
                        },
                        '& .MuiInputLabel-root': {
                          color: '#9c9c9d', // Default color
                          '&.Mui-focused': {
                            color: '#304e95', // Color when focused
                          },
                        },
                      }}
                      name="email"
                      label="Mail"
                      fullWidth
                      margin="normal"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                {/* Message Field */}
                <Grid item xs={12} paddingTop={2}>
                  <h5
                    style={{
                      color: '#304e95',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '20px',
                      lineHeight: '20px',
                      fontFamily: 'Trebuchet MS',
                      textAlign: 'left', // Center text within the element
                    }}>
                    ¿CÓMO PODEMOS AYUDARTE?
                  </h5>
                  <TextField
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#9c9c9d',
                        },
                        '&:hover fieldset': {
                          borderColor: '#304e95', // Color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#304e95', // Focused color
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: hasContent('name') ? '#304e95' : '#FF5733',
                      },
                      '& .MuiInputLabel-root': {
                        color: '#9c9c9d', // Default color
                        '&.Mui-focused': {
                          color: '#304e95', // Color when focused
                        },
                      },
                    }}
                    name="message"
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Grid item container xs={12} justifyContent={'center'} paddingBottom={2}>
              <button
                style={{
                  backgroundColor: '#304e95',
                  padding: '10px 20px',
                  width: 300,
                  height: '50px',
                  textAlign: 'center',
                  boxShadow: '0px 3px 6px #00000029',
                  borderRadius: '7px',
                  display: 'flex', // Use flexbox
                  justifyContent: 'center', // Center content horizontally
                  alignItems: 'center',
                  border: 'none',
                }}
                onClick={handleClick}>
                <Grid container item alignItems={'center'} justifyContent={'center'}>
                  <h4 style={fontStyles.buttonWhite}>Enviar mensaje</h4>
                </Grid>
              </button>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ContactModal;

