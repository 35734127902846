import { Grid } from '@mui/material';
import { useFetchData } from '../hooks/useFetchData';
import Lote from './Lotes';

function LotsGrid() {
  const state = useFetchData(`/api/destacados-gdas?populate=*`);
  const { data } = state;

  return (
    <Grid item container xs={12} direction={{ xs: 'column', md: 'row', lg: 'row' }} paddingY={4}>
      <Lote
        image={data?.data?.[0].attributes?.image.data[0].attributes.url}
        title={data?.data?.[0].attributes?.title}
        text1={data?.data?.[0].attributes?.description}
        url={data?.data?.[0].attributes?.url}
        lotType={data?.data?.[0].attributes?.lotType}
        color="#ffffff"
        textColor="#304e95"></Lote>
      <Lote
        image={data?.data?.[1].attributes?.image.data[0].attributes.url}
        title={data?.data?.[1].attributes?.title}
        text1={data?.data?.[1].attributes?.description}
        url={data?.data?.[1].attributes?.url}
        lotType={data?.data?.[1].attributes?.lotType}
        textColor="#ffffff"
        color="#304e95"></Lote>
      <Lote
        image={data?.data?.[2].attributes?.image.data[0].attributes.url}
        title={data?.data?.[2].attributes?.title}
        text1={data?.data?.[2].attributes?.description}
        url={data?.data?.[2].attributes?.url}
        lotType={data?.data?.[2].attributes?.lotType}
        color="#ffffff"
        textColor="#304e95"></Lote>
      <Lote
        image={data?.data?.[3].attributes?.image.data[0].attributes.url}
        title={data?.data?.[3].attributes?.title}
        text1={data?.data?.[3].attributes?.description}
        url={data?.data?.[3].attributes?.url}
        lotType={data?.data?.[3].attributes?.lotType}
        textColor="#ffffff"
        color="#304e95"></Lote>
    </Grid>
  );
}

export default LotsGrid;

