import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import FondoFooter from '../../../../commons/assets/FondoFooter.svg';
import LogoTattersall from '../../../../commons/assets/LOGOS GDA_BLANCO.png';
import FacebookLogo from '../../../../commons/assets/icons/FacebookBlanco.svg';
import InstagramLogo from '../../../../commons/assets/icons/instagramBlanco.svg';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        backgroundColor: '#314D95', // Fallback color if SVG fails to load
        backgroundImage: `url(${FondoFooter})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        bottom: 0,
        width: '100%',
        height: 'auto',
      }}>
      <Container maxWidth="lg">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
          direction="column"
          sx={{ width: '100%' }} // Ensure full width
          paddingBottom={3}>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Box
              component="img"
              src={LogoTattersall}
              alt="My Image"
              sx={{
                width: { xs: '100%', sm: 250 },
                height: 'auto',
                borderRadius: '8px',
                boxShadow: 0,
                maxWidth: 200,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction={{ xs: 'column', sm: 'row' }} // Stack columns on mobile, row on small and up
          justifyContent="center"
          alignItems="flex-start" // Align items to the top of each column
          spacing={4}>
          <Grid item xs={12} sm={4} sx={{ height: '100%', width: '100%' }} alignItems="center">
            <Box
              sx={{
                width: '80%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <Typography
                paddingBottom={3}
                variant="body2"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '22px',
                  lineHeight: '28px',
                  fontFamily: 'Trebuchet MS',
                }}>
                OPERAMOS EN TODO CHILE <br />
                OFICINAS EN:
              </Typography>
              <Typography
                paddingBottom={1}
                variant="body2"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Santiago
              </Typography>
              <Typography
                variant="body2"
                paddingBottom={2}
                style={{
                  color: 'white',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Isidora Goyenechea 3600, Piso 5, Las Condes
              </Typography>
              <Typography
                paddingBottom={1}
                variant="body2"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Buin
              </Typography>
              <Typography
                variant="body2"
                paddingBottom={2}
                style={{
                  color: 'white',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Caletera, San Bernardo, Buin.
              </Typography>
              <Typography
                paddingBottom={1}
                variant="body2"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Puerto Varas
              </Typography>
              <Typography
                variant="body2"
                paddingBottom={3}
                style={{
                  color: 'white',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '17px',
                  fontFamily: 'Trebuchet MS',
                }}>
                Gramados S/N.
              </Typography>
              <Typography
                paddingBottom={1}
                variant="body2"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '22px',
                  lineHeight: '28px',
                  fontFamily: 'Trebuchet MS',
                }}>
                CANAL DE DENUNCIAS
              </Typography>
              <a href="https://tattersall.cl/index.php/canal-de-denuncias">
                <Typography
                  variant="body2"
                  paddingBottom={3}
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontFamily: 'Trebuchet MS',
                    textDecoration: 'underline',
                  }}>
                  Aquí
                </Typography>
              </a>
            </Box>
          </Grid>
          <Grid
            container
            xs={12}
            sm={6}
            alignItems="center"
            paddingTop={4}
            paddingLeft={{ sm: 4, xs: 8 }}
            spacing={2}>
            <Grid item container direction={'row'}>
              <Typography
                variant="body2"
                paddingBottom={2}
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '22px',
                  lineHeight: '28px',
                  fontFamily: 'Trebuchet MS',
                }}>
                CONTACTO
              </Typography>
            </Grid>
            <Grid item container direction={'row'} spacing={{ sm: 14, xs: 4 }}>
              <Grid item>
                <Typography
                  variant="body2"
                  paddingBottom={1}
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  Propiedades
                </Typography>
                <Typography
                  paddingBottom={1}
                  variant="body2"
                  style={{
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  {isMobile ? (
                    <a
                      href={`tel:+569 2362 3090`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        paddingLeft: 2,
                      }}>
                      (+562) 2362 3090
                    </a>
                  ) : (
                    <span>(+562) 2362 3090</span>
                  )}
                </Typography>
                <Typography
                  paddingBottom={2}
                  variant="body2"
                  style={{
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: 'Trebuchet MS',
                    display: 'flex', // Align items in a row
                    alignItems: 'center', // Center-align items vertically
                  }}>
                  <WhatsAppIcon style={{ marginRight: '4px', fontSize: '18px' }} />{' '}
                  {/* Adjust size if needed */}
                  {isMobile ? (
                    <a
                      href={`tel:+569 3197 2877`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        paddingLeft: 2,
                      }}>
                      (+569) 3197 2877
                    </a>
                  ) : (
                    <span>(+569) 3197 2877</span>
                  )}
                </Typography>
                <Grid container direction="row" spacing={2} paddingBottom={2}>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://web.facebook.com/profile.php?id=61558079620526')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={FacebookLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://www.instagram.com/tattersall_propiedades/?hl=es')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={InstagramLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  paddingBottom={1}
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  Arriendos
                </Typography>
                <Typography
                  paddingBottom={2}
                  variant="body2"
                  style={{
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  {isMobile ? (
                    <a
                      href={`tel:+569 4986 73 74`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        paddingLeft: 2,
                      }}>
                      (+569) 4986 73 74
                    </a>
                  ) : (
                    <span>(+569) 4986 73 74</span>
                  )}
                </Typography>
                <Grid container direction="row" spacing={2} paddingBottom={2}>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href = 'https://www.facebook.com/tattersallap/')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={FacebookLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://www.instagram.com/tattersall_propiedades/?hl=es')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={InstagramLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container direction={'row'} spacing={{ sm: 14, xs: 4 }}>
              <Grid item>
                <Typography
                  variant="body2"
                  paddingBottom={1}
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  Campos
                </Typography>
                <Typography
                  paddingBottom={2}
                  variant="body2"
                  style={{
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: 'Trebuchet MS',
                    display: 'flex', // Align items in a row
                    alignItems: 'center', // Center-align items vertically
                  }}>
                  <WhatsAppIcon style={{ marginRight: '4px', fontSize: '18px' }} />{' '}
                  {/* Adjust size if needed */}
                  {isMobile ? (
                    <a
                      href={`tel:+569 3187 4340`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        paddingLeft: 2,
                      }}>
                      (+569) 3187 4340
                    </a>
                  ) : (
                    <span>(+569) 3187 4340</span>
                  )}
                </Typography>
                <Grid container direction="row" spacing={2} paddingBottom={2}>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://web.facebook.com/profile.php?id=61558079620526')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={FacebookLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href = 'https://www.instagram.com/tattersall_campos/')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={InstagramLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  paddingBottom={1}
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  Remates Bienes
                </Typography>
                <Typography
                  paddingBottom={1}
                  variant="body2"
                  style={{
                    color: 'white',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: 'Trebuchet MS',
                  }}>
                  {isMobile ? (
                    <a
                      href={`tel:800 123 990`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        paddingLeft: 2,
                      }}>
                      800 123 990
                    </a>
                  ) : (
                    <span>800 123 990</span>
                  )}
                </Typography>
                <Grid container direction="row" spacing={2} paddingBottom={2}>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://web.facebook.com/tattersall.remates/?locale=es_LA&_rdc=1&_rdr')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={FacebookLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href =
                          'https://www.instagram.com/tattersall_remates/?hl=es')
                      }
                      style={{ padding: 0 }}>
                      <img
                        src={InstagramLogo}
                        alt="Facebook Icon"
                        style={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

